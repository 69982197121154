import { createContext, useContext } from "react";
import DE from '../../i18n/de';

export type LanguageContextProps = {
    lang: string;
    translations: typeof DE
}

export const LanguageContext = createContext<LanguageContextProps>({} as LanguageContextProps)

const useTranslations = () => {
    const languageContext = useContext(LanguageContext);

    return languageContext.translations;
}

export default useTranslations;