import { BuildingType } from "../config/buildings";
import { ProductType } from "../config/products";
import { TechSector } from "../config/tech-sector";

const catalogText = 'Zum Produktkatalog';
const landingpageText = 'Zur Detailseite';
const planingText = 'Zur Auslegung';

const defaultWasteWaterProductLinks = (linkToPlaning?: string) => [
    {
        text: planingText,
        href: linkToPlaning ?? 'https://www.ksb.com/de-de/software-und-know-how/auslegungstools-und-hilfen/ksbuilding-consult'
    },
    {
        text: 'Zur Know-how-Broschüre',
        href: 'https://live-resources-e2e-sales.ksb.com/resource/blob/166112/fcac767dc8ef8b94919b12aed4fda6d9/dow-2drainage-planning-data.pdf'
    }
]

const digitalDataLinks = [
    {
        text: 'Zur Detailseite',
        href: 'https://www.ksb.com/de-de/anwendungen/gebaeudetechnik/produkte/strangregulierventile'
    },
    {
        text: 'Digitale Daten',
        href: 'https://www.ksb.com/de-de/software-und-know-how/auslegungstools-und-hilfen/digitale-produktdaten-armaturen'
    }
]

const defaultHeatingProductLinks = (linkToPlaning?: string) => [
    {
        text: planingText,
        href: linkToPlaning ?? 'https://www.ksb.com/de-de/software-und-know-how/auslegungstools-und-hilfen/ksbuilding-consult'
    }
]

const heatingLandingpageLink = {
    text: landingpageText,
    href: 'https://www.ksb.com/de-de/anwendungen/gebaeudetechnik/produkte/heizungspumpen-von-ksb'
}

const defaultWaterProductLinks = (linkToPlaning?: string) => [
    {
        text: planingText,
        href: linkToPlaning ?? 'https://www.ksb.com/de-de/software-und-know-how/auslegungstools-und-hilfen/ksbuilding-consult'
    }
]

const de = {
    appTitle: 'KSBuilding City',
    appSubTitle: 'Entdecken Sie das gebäudetechnische Angebot von KSB interaktiv in der KSBuilding City',
    backToCity: 'zurück zur KSBuilding City',
    back: 'zurück',
    city: {
        comingSoonBuilding: 'Richtfest in Kürze',
        comingSoonTechSector: 'Coming soon',
        newBuilding: 'Neu',
    },
    buildingDetails: {
        navigationTitle: 'Wählen Sie einen Anwendungsbereich',
        navigationDescription:
            'Wählen Sie ein Produkt aus und erfahren Sie alles rund um die Anwendung und die Auslegung.',
        new: 'Neu',
    },
    newsletter: {
        title: 'Keine Neuheiten in der KSBuilding City verpassen?',
        link: {
            text: 'Hier zum Newsletter anmelden!',
            href: 'https://go.ksb.com/de-de-ksbuilding-newsletter',
        },
    },
    contact: {
        text: 'Kontakt',
        href: 'https://www.ksb.com/de-de/kontakt',
    },
    buildings: {
        [BuildingType.HOUSE]: {
            name: 'Einfamilienhaus',
        },
        [BuildingType.APARTMENT]: {
            name: 'Mehrfamilienhaus',
        },
        [BuildingType.HOSPITAL]: {
            name: 'Krankenhaus',
        },
        [BuildingType.SCHOOL]: {
            name: 'Schulgebäude',
        },
        [BuildingType.ADMINISTRATION]: {
            name: 'Verwaltung',
        },
        [BuildingType.SKYSCRAPER]: {
            name: 'Skyscraper',
        },
        [BuildingType.INDUSTRY]: {
            name: 'Industrie',
        },
        [BuildingType.INDUSTRY_LAB]: {
            name: 'Labor',
        },
        [BuildingType.INDUSTRY_PRODUCTION]: {
            name: 'Produktionsgebäude',
        },
        [BuildingType.INDUSTRY_TESTCENTER]: {
            name: 'Prüfstand',
        },
        [BuildingType.INDUSTRY_SPRINKLER_CENTER]: {
            name: 'Sprinklerzentrale',
        },
        [BuildingType.INDUSTRY_WATER]: {
            name: 'Zentrale Wasserversorgung',
        },
        [BuildingType.FACTORY]: {
            name: 'Fabrikgelände',
        },
        [BuildingType.FACTORY_HEATING]: {
            name: 'Heizzentrale',
        },
        [BuildingType.FACTORY_SPRINKLER_CENTER]: {
            name: 'Sprinklerzentrale',
        },
        [BuildingType.FACTORY_PROCESSES]: {
            name: 'Industrielle Prozesse',
        },
        [BuildingType.FACTORY_PRODUCTION]: {
            name: 'Produktionsgebäude',
        },
        [BuildingType.HOTEL]: {
            name: 'Hotel',
        },
        [BuildingType.KSB_CENTER]: {
            name: 'News',
            headline: 'KSB präsentiert:',
            teaser: {
                title: 'AmaDrainer 3',
                text: 'Die neue Generation der bewährten Schmutzwasser-Tauchmotorpumpen',
                footer: "Hier geht's zum neuen AmaDrainer in seiner gewohnten Umgebung",
            },
            linkList: [
                {
                    text: 'Online-Seminare',
                    href: 'https://www.ksb.com/de-de/service/schulung-und-training/on-line-mit-ksb',
                },
                {
                    text: 'Auslegungs-Tools und Planungshilfen',
                    href: 'https://www.ksb.com/de-de/software-und-know-how/auslegungstools-und-hilfen',
                },
                {
                    text: 'E-Paper-Portal',
                    href: 'https://oxomi.com/p/2024617/switch',
                },
                {
                    text: 'Produktkatalog',
                    href: 'https://www.ksb.com/de-de/produkte/produktkatalog',
                },
                {
                    text: 'Kreiselpumpenlexikon',
                    href: 'https://www.ksb.com/de-global/kreiselpumpenlexikon/',
                },
                {
                    text: 'PR & Aktuelles',
                    href: 'https://www.ksb.com/de-de/unternehmen/pressebereich',
                },
                {
                    text: 'Ausbildung & Beruf',
                    href: 'https://www.ksb.com/de-de/unternehmen/karriere',
                },
                {
                    text: 'Planungssupport online',
                    href: 'https://www.ksb.com/de-de/software-und-know-how/planungssupport-online',
                },
            ],
        },
        [BuildingType.KSB_TEAM]: {
            name: 'Team',
        },
    },
    sectors: {
        [TechSector.WATER]: {
            name: 'Wasserversorgung',
        },
        [TechSector.HEATING]: {
            name: 'Heizung / Klima / Kälte',
        },
        [TechSector.WASTE_WATER]: {
            name: 'Entwässerung',
        },
        [TechSector.FIRE_PROTECTION]: {
            name: 'Brandschutz',
        },
    },
    products: {
        [ProductType.AMA_DRAINER_3]: {
            name: 'AmaDrainer 3',
            links: [
                {
                    text: 'Zur Auslegung',
                    href: 'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Entwässerung.Schmutzwasser-Tauchmotorpumpen.Schmutzwasserpumpen.',
                },
                {
                    text: 'Zur Detailseite',
                    href: 'https://www.ksb.com/de-de/anwendungen/gebaeudetechnik/produkte/amadrainer3',
                },
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/amadrainer-3/A07B',
                },
                {
                    text: 'Zur Know-how-Broschüre',
                    href: 'https://live-resources-e2e-sales.ksb.com/resource/blob/166112/fcac767dc8ef8b94919b12aed4fda6d9/dow-2drainage-planning-data.pdf',
                },
            ],
        },
        [ProductType.MINI_COMPACTA]: {
            name: 'MiniCompacta',
            links: [
                ...defaultWasteWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Entwässerung.Hebeanlagen.Fäkalienhebeanlagen.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/system/mini-compacta/M09B',
                },
            ],
        },
        [ProductType.AMA_DRAINER_BOX_UNTERFLUR]: {
            name: 'AmaDrainer Box Unterflur',
            links: [
                ...defaultWasteWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Entwässerung.Hebeanlagen.Schmutzwasserhebeanlagen.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/system/ama-drainer-box-ama-drainer-box-mini/A23A',
                },
            ],
        },
        [ProductType.CK_800]: {
            name: 'Pumpstation CK 800',
            links: [
                ...defaultWasteWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Entwässerung.Pumpstationen (mit Ex Schutz).',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/system/pumpstation-ck-1000/C05A',
                },
            ],
        },
        [ProductType.AMAREX]: {
            name: 'Amarex',
            links: [
                ...defaultWasteWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Entwässerung.Schmutzwasser-Tauchmotorpumpen.Tauchmotorpumpen (mit/ ohne Ex Schutz).',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/tauchmotorpumpe/amarex/A31B',
                },
            ],
        },
        [ProductType.COMPACTA]: {
            name: 'Compacta',
            links: [
                ...defaultWasteWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Entwässerung.Hebeanlagen.Fäkalienhebeanlagen.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/system/compacta/C00B',
                },
            ],
        },
        [ProductType.AMA_DRAINER_4_5]: {
            name: 'AmaDrainer 4/5',
            links: [
                ...defaultWasteWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Entwässerung.Schmutzwasser-Tauchmotorpumpen.Schmutzwasserpumpen.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/tauchmotorpumpe/ama-drainer-/A76A',
                },
            ],
        },
        [ProductType.AMA_DRAINER_BOX_UEBERFLUR]: {
            name: 'AmaDrainer Box Überflur',
            links: [
                ...defaultWasteWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Entwässerung.Hebeanlagen.Schmutzwasserhebeanlagen.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/system/ama-drainer-box-ama-drainer-box-mini/A23A',
                },
            ],
        },
        [ProductType.AMAPORTER]: {
            name: 'Ama-Porter',
            links: [
                ...defaultWasteWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Entwässerung.Schmutzwasser-Tauchmotorpumpen.Schmutzwasserpumpen.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/tauchmotorpumpe/ama-porter/A10A',
                },
            ],
        },
        [ProductType.LEVELCONTROL]: {
            name: 'LevelControl Basic 2',
            links: [
                ...defaultWasteWaterProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpenautomatisierung/levelcontrol-basic-2/L20A',
                },
            ],
        },
        [ProductType.BOAX_S_SF]: {
            name: 'BOAX-S/SF',
            links: [
                {
                    text: planingText,
                    href: 'https://www.ksb.com/de-de/software-und-know-how/auslegungstools-und-hilfen/ksbuilding-consult',
                },
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/klappe/boax-s-sf/B12A',
                },
            ],
        },
        [ProductType.CALIO]: {
            name: 'Calio',
            links: [
                ...defaultHeatingProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Heizung/Klima/Kälte.Heizung.Naßläufer Umwälzpumpen.Einzelpumpen.',
                ),
                { ...heatingLandingpageLink },
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/trocken-aufgestellte-pumpe/calio/C89B',
                },
            ],
        },
        [ProductType.CALIO_Z]: {
            name: 'Calio Z',
            links: [
                ...defaultHeatingProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Heizung/Klima/Kälte.Heizung.Naßläufer Umwälzpumpen.Zwillingspumpe.',
                ),
                { ...heatingLandingpageLink },
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/trocken-aufgestellte-pumpe/calio-z/C09B',
                },
            ],
        },
        [ProductType.BOA_RVK]: {
            name: 'BOA-RVK',
            links: [
                ...defaultHeatingProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/rueckschlagventil/boa-rvk/B11A',
                },
            ],
        },
        [ProductType.BOA_COMPACT]: {
            name: 'BOA-Compact',
            links: [
                ...defaultHeatingProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/ventil/boa-compact/B01A',
                },
            ],
        },
        [ProductType.BOA_CONTROL_PIC]: {
            name: 'BOA-Control PIC',
            links: [
                ...digitalDataLinks,
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/ventil/boa-control-pic/B75A',
                },
            ],
        },
        [ProductType.BOATRONIC_MS_420]: {
            name: 'BOATRONIC MS-420',
            links: [
                ...defaultHeatingProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armaturenautomatisierung/boatronic-ms-boatronic-ms-420/B23B',
                },
            ],
        },
        [ProductType.BOA_CONTROL_DPR]: {
            name: 'BOA-Control DPR',
            links: [
                ...digitalDataLinks,
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/ventil/boa-control-dpr/B66A',
                },
            ],
        },
        [ProductType.BOA_CONTROL_SBV]: {
            name: 'BOA-Control SBV',
            links: [
                ...digitalDataLinks,
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/ventil/boa-control-sbv/B79A',
                },
            ],
        },
        [ProductType.ETALINE_Z]: {
            name: 'Etaline Z',
            links: [
                ...defaultHeatingProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Heizung/Klima/Kälte.Heizung.Trockenläuferpumpen geregelt.Zwillingspumpe.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/trocken-aufgestellte-pumpe/etaline-z/E13B',
                },
            ],
        },
        [ProductType.ETANORM]: {
            name: 'Etanorm',
            links: [
                ...defaultHeatingProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Heizung/Klima/Kälte.Heizung.Trockenläuferpumpen geregelt.Einzelpumpen.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/trocken-aufgestellte-pumpe/etanorm/E04B',
                },
            ],
        },
        [ProductType.SISTO_16]: {
            name: 'SISTO-16RGA',
            links: [
                ...defaultHeatingProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/membranventil/sisto-16/S40A',
                },
            ],
        },
        [ProductType.CALIO_THERM_S]: {
            name: 'CalioTherm S Pro',
            links: [
                ...defaultHeatingProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Heizung/Klima/Kälte.Heizung.Naßläufer Umwälzpumpen.Einzelpumpen.',
                ),
                { ...heatingLandingpageLink },
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/trocken-aufgestellte-pumpe/calio-therm-s/C91B',
                },
            ],
        },
        [ProductType.CALIO_S]: {
            name: 'Calio S Pro',
            links: [
                ...defaultHeatingProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Heizung/Klima/Kälte.Heizung.Naßläufer Umwälzpumpen.Einzelpumpen.',
                ),
                { ...heatingLandingpageLink },
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/trocken-aufgestellte-pumpe/calio-s/C90B',
                },
            ],
        },
        [ProductType.MULTI_ECO]: {
            name: 'MultiEco',
            links: [
                ...defaultWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Wasserversorgung.Hauswasserwerke.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/trocken-aufgestellte-pumpe/multi-eco/M17A',
                },
            ],
        },
        [ProductType.FILTRA]: {
            name: 'Filtra',
            links: [
                ...defaultWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Wasserversorgung.Schwimmbad.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/trocken-aufgestellte-pumpe/filtra-n/F00A',
                },
            ],
        },
        [ProductType.HYA_RAIN_N]: {
            name: 'Hya Rain N',
            links: [
                ...defaultWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Wasserversorgung.Regenwassernutzung.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/system/hya-rain-hya-rain-n/H12A',
                },
            ],
        },
        [ProductType.DELTA_BASIC_COMPACT_MVP]: {
            name: 'DeltaCompact',
            links: [
                ...defaultWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Wasserversorgung.Druckerhöhungsanlagen.Mehrpumpen Druckerhöhungsanlagen.Drehzahlgeregelt.',
                ),
                {
                    text: landingpageText,
                    href: 'https://www.ksb.com/de-de/anwendungen/gebaeudetechnik/produkte/druckerhoehungsanlagen',
                },
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/system/ksb-delta-compact/D05B',
                },
            ],
        },
        [ProductType.IXO_PRO]: {
            name: 'Ixo Pro',
            links: [
                ...defaultWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Wasserversorgung.Brunnen.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/unterwassermotorpumpe/ixo-pro/I06A',
                },
            ],
        },
        [ProductType.BOA_COMPACT_EKB]: {
            name: 'BOA-Compact EKB',
            links: [
                ...defaultWaterProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/ventil/boa-compact-ekb/B02A',
                },
            ],
        },
        [ProductType.SISTO_16_RGA]: {
            name: 'SISTO-16RGA',
            links: [
                ...defaultWaterProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/membranventil/sisto-16/S40A',
                },
            ],
        },
        [ProductType.SISTO_16_TWA]: {
            name: 'SISTO-16TWA',
            links: [
                ...defaultWaterProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/membranventil/sisto-16twa/S43A',
                },
            ],
        },
        [ProductType.MOVITEC]: {
            name: 'Movitec',
            links: [
                ...defaultWaterProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/gliederpumpe/movitec/M12A',
                },
            ],
        },
        [ProductType.MOVITEC_VCI]: {
            name: 'Movitec VCI',
            links: [
                ...defaultWaterProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/wellentauchpumpe/movitec-vci/M94A',
                },
            ],
        },
        [ProductType.KSB_DELTA_PRIMO_SVP]: { //auch DeltaMacro genannt
            name: 'DeltaPrimo SVP',
            links: [
                ...defaultWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Wasserversorgung.Druckerhöhungsanlagen.Mehrpumpen Druckerhöhungsanlagen.',
                ),
                {
                    text: landingpageText,
                    href: 'https://www.ksb.com/de-de/anwendungen/gebaeudetechnik/produkte/druckerhoehungsanlagen',
                },
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/ksb-delta-primo/D08A',
                },
            ],
        },
        [ProductType.KSB_SAFETY_BOOST]: {
            name: 'KSB Safety Boost ',
            links: [
                ...defaultWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Wasserversorgung.Trennstationen.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/system/ksb-safety-boost/SA2A',
                },
            ],
        },
        [ProductType.UPA]: {
            name: 'UPA',
            links: [
                ...defaultWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Wasserversorgung.Brunnen.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/unterwassermotorpumpe/upa-100c-upa-150c/U16A',
                },
            ],
        },
        [ProductType.MEGA_CPK]: {
            name: 'MegaCPK',
            links: [
                ...defaultWaterProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/trocken-aufgestellte-pumpe/megacpk/M48A',
                },
            ],
        },
        [ProductType.ECOLINE_GT_40]: {
            name: 'Ecoline GT 40',
            links: [
                ...defaultWaterProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-at/lc/produkte/armatur/schieber/ecoline-gt-40/EF2A',
                },
            ],
        },
        [ProductType.SISTO_RSK]: {
            name: 'Sisto-RSK',
            links: [
                ...defaultWaterProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/rueckschlagklappe/sisto-rsk/S65A',
                },
            ],
        },
        [ProductType.ETALINE]: {
            name: 'Etaline',
            links: [
                ...defaultWaterProductLinks(
                    'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Heizung/Klima/Kälte.Heizung.Trockenläuferpumpen geregelt.Einzelpumpen.',
                ),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/trocken-aufgestellte-pumpe/etaline/E03B',
                },
            ],
        },
        [ProductType.BOA_CVE]: {
            name: 'BOA-CVE',
            links: [
                ...defaultWaterProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/ventil/boa-cve-h/B26A',
                },
            ],
        },
        [ProductType.BOA_CVE_IMS]: {
            name: 'BOA-CVE IMS',
            links: [
                ...defaultHeatingProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/ventil/boa-cve-c-cs-w-ims-ekb-ims-ekb/B04A',
                },
            ],
        },
        [ProductType.BOAX_MAT]: {
            name: 'BOAXMAT',
            links: [
                ...defaultWaterProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/klappe/boax-s-sf/B12A',
                },
            ],
        },
        [ProductType.BOA_CONTROL_IMS]: {
            name: 'BOA‑Control IMS EKB',
            links: [
                ...defaultWaterProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/ventil/boa-control-boa-control-ims/B05B',
                },
            ],
        },
        [ProductType.SERIE_2000]: {
            name: 'Serie 2000',
            links: [
                ...defaultWaterProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/armatur/rueckschlagklappe/serie-2000/S51A',
                },
            ],
        },
        [ProductType.HPK]: {
            name: 'HPK',
            links: [
                ...defaultWaterProductLinks(),
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/pumpe/trocken-aufgestellte-pumpe/hpk/H02A',
                },
            ],
        },
        [ProductType.ETANORM_FXV]: {
            name: 'Etanorm FXV',
            links: [
                {
                    text: landingpageText,
                    href: 'https://www.ksb.com/de-de/anwendungen/gebaeudetechnik/brandbekaempfung/sprinkler',
                },
            ],
        },
        [ProductType.HYADUO_2_D_FL_COMPACT]: {
            name: 'HyaDuo 2 D FL Compact',
            links: [
                {
                    text: planingText,
                    href: 'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Brandschutz.Hydrantenanlagen.Doppelpumpen-Feuerlöschanlagen.',
                },
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/system/druckerhoehungsanlage/hyaduo-2-d-fl-compact/H46B',
                },
            ],
        },
        [ProductType.UPA_VDS]: {
            name: 'UPA (VDS)',
            links: null,
        },
        [ProductType.HYA_DUO_2_D_FL]: {
            name: 'Hya-Duo 2 D FL',
            links: [
                {
                    text: planingText,
                    href: 'https://www.ksbuilding-consult.com/pumpdeeplink.aspx?Startoption=S4P_ApplRangeSelection&AW__APPLRG=KSB.Gebäudetechnik.Brandschutz.Hydrantenanlagen.Doppelpumpen-Feuerlöschanlagen.',
                },
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-de/lc/produkte/system/druckerhoehungsanlage/hyaduo-2-d-fl/H44B',
                },
            ],
        },
        [ProductType.HYASOLO_2_D_FL_COMPACT]: {
            name: 'HyaSolo 2 D FL Compact',
            links: [
                {
                    text: planingText,
                    href: 'https://www.ksbuilding-consult.com/ApplRange.aspx',
                },
                {
                    text: catalogText,
                    href: 'https://www.ksb.com/de-at/lc/produkte/system/druckerhoehungsanlage/hyasolo-2-d-fl-compact/H45B',
                },
            ],
        },
    },
};

export default de;
