import { forwardRef, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import BuildingWrapper from './building-wrapper';
import PageHeader from './ui/page-header';
import bgImg from '../assets/buildings/ksb-team/bg.jpg';
import beck from '../assets/buildings/ksb-team/beck.png';
import beckOverlay from '../assets/buildings/ksb-team/beck-overlay.png';
import bollmann from '../assets/buildings/ksb-team/bollmann.png';
import bollmannOverlay from '../assets/buildings/ksb-team/bollmann-overlay.png';
import terock from '../assets/buildings/ksb-team/terock.png';
import terockOverlay from '../assets/buildings/ksb-team/terock-overlay.png';
import manss from '../assets/buildings/ksb-team/manss.png';
import manssOverlay from '../assets/buildings/ksb-team/manss-overlay.png';
import schmidt from '../assets/buildings/ksb-team/schmidt.png';
import schmidtOverlay from '../assets/buildings/ksb-team/schmidt-overlay.png';
import useTranslations from './utils/language-provider';
import { BuildingType } from '../config/buildings';
import Carousel from './lib/react-carousel3d';
import { Anchor } from './ui/button';
import { ReactComponent as ArrowRight } from '../assets/arrow-right.svg';

type KSBTeamProps = {}

const Background = styled.div`
    position: relative;
    display: flex;
    height: calc(100% - 75px);
    background: url(${bgImg});
    background-size: contain;
    background-repeat: no-repeat;
    align-items: center;
`

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 28% 10px 10px;
    background: rgba(255,255,255,.8);
    color: ${props => props.theme.colors.primaryDark};
    font-size: 12px;

    @media ${props => props.theme.screens.md} {
        font-size: 18px;
    }

    @media ${props => props.theme.screens.lg} {
        padding: 30px 100px 30px 30px;
        margin: 0 0 0 80px;
    }

    img {
        position: absolute;
        right: 30px;
        bottom: 0;
        height: 95%;
       
        @media ${props => props.theme.screens.lg} {
            display: none;
        }
    }
`

const TextBoxTitle = styled.div`
    font-weight: 700;

    @media ${props => props.theme.screens.md} {
        font-size: 32px;
        font-weight: 900;
        text-transform: uppercase;
    }
`

const CarouselWrapper = styled.div`
    position: absolute;
    bottom: 0px;
    right: 0px;
    flex-shrink: 0;

    @media ${props => props.theme.screens.lg} {
        position: relative;
        width: 48%;
        height: 100%;
    }
`

const StyledCarousel = styled(Carousel)`
    display: none;

    @media ${props => props.theme.screens.lg} {
       display: block;
    }
`

const CarouselImage = styled.img`
    height: 95%;
`

const OverlayImage = styled.img<{active: boolean}>`
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 95%;
    transition: all 300;
    opacity: ${props => props.active ? '0' : '.5'};
`

const CarouselButton = styled.button<{direction: string}>`
    margin: 0 10px 20px;
    z-index: 1000;
    width: 40px;
    height: 40px;
    color: ${props => props.theme.colors.primary};
    border: none;
    background: #fff;
    padding: 0;
    padding-top: 3px;
    padding-left: ${props => props.direction === 'prev' ? '0' : '3px'};
    border-radius: 100%;
    opacity: .5;
    transition: all 300ms;

    @media ${props => props.theme.screens.lg} {
        position: absolute;
        bottom: 220px;
        left: ${props => props.direction === 'prev' ? '150px' : '430px'};
        margin: 0;
    }

    &:hover {
        opacity: 1;
        transform: scale(1.2);
    }

    svg {
        width: 13px;
        transform: ${props => props.direction === 'prev' ? 'rotate(180deg)' : 'none'};
    }
`

const items = [
    {
        img: bollmann,
        overlay: bollmannOverlay,
        width: '187px',
        height: '600px',
        title: 'Der Partner, der den Durchblick behält, wo andere im Dunkeln tappen.',
        href: 'https://www.ksb.com/de-de/software-und-know-how/know-how/ksb-know-how-broschueren',
        content: (<>
            Lösungen entwickeln, die nicht nur zufriedenstellen, sondern begeistern. Für jedes Projekt die richtige Pumpe finden. Und  auf jede Frage eine Antwort wissen.
            <br />
            Deshalb ist <strong>Andreas Bollmann</strong>:
            <br /><br />
            <strong>Verrückt nach Gebäudetechnik.</strong>
        </>)
    },
    {
        img: terock,
        overlay: terockOverlay,
        width: '245px',
        height: '600px',
        title: 'Der Partner, der wissen teilt, wo andere mit den Achseln zucken.',
        href: 'https://www.ksb.com/de-de/software-und-know-how/online-seminare-on-line-mit-ksb',
        content: (<>
            Praktische Tipps und Tricks rund um KSB-Produkte vermitteln. Für Planung, Einbau, Betrieb oder Wartung. Damit die Pumpe nicht nur brummt, sondern auch fördert.
            <br />
            Deshalb ist <strong>Bernhard Terock</strong>:
            <br /><br />
            <strong>Verrückt nach Gebäudetechnik.</strong>
        </>)
    },
    {
        img: manss,
        overlay: manssOverlay,
        width: '207px',
        height: '600px',
        title: 'Unser Fachmann, der BIM lebt, wo andere eindimensional bleiben.',
        href: 'https://www.ksb.com/de-de/software-und-know-how/auslegungstools-und-hilfen/digitale-produktdaten-armaturen',
        content: (<>
            Planer kompetent unterstützen. Mit strukturierten Produktinformationen und 3D-Visualisierungen der Pumpe oder  Armatur. Einfach über das Datenportal BIM (Building Information Modelling).
            <br />
            Deshalb ist <strong>Daniel Manss</strong>:
            <br /><br />
            <strong>Verrückt nach Gebäudetechnik.</strong>
        </>)
    },
    {
        img: schmidt,
        overlay: schmidtOverlay,
        width: '185px',
        height: '600px',
        title: 'Das Planungsteam, bei dem es schon "Klick" macht, wo andere noch grübeln.',
        href: 'https://www.ksb.com/de-de/software-und-know-how/auslegungstools-und-hilfen/ksbuilding-consult',
        content: (<>
            Gebäudetechnische Anlagen sicher planen. Kunden von der Entwurfsphase über Ausschreibungen bis hin zur Kalkulation begleiten. Einfach und intuitiv mit KSBuilding Consult.
            <br />
            Deshalb ist <strong>Sebastian Schmidt</strong>:
            <br /><br />
            <strong>Verrückt nach Gebäudetechnik.</strong>
        </>)
    },
    {
        img: beck,
        overlay: beckOverlay,
        width: '171px',
        height: '600px',
        title: 'Die Crew, die Probleme knackt, wo andere sich die Zähne ausbeissen.',
        href: 'https://www.ksb.com/de-de/service/ksb-service-partner',
        content: (<>
            Im Servicefall bei Pumpenanlagen schnelle und umfassende Hilfe bieten. Jedes Aggregat bis ins letzte Detail kennen. Fachgerecht inspizieren, warten und instandsetzen.
            <br />
            Deshalb ist <strong>Ralph Beck</strong>:
            <br /><br />
            <strong>Verrückt nach Gebäudetechnik.</strong>
        </>)
    },
]

const KSBTeam = forwardRef<HTMLDivElement, KSBTeamProps>((props, ref) => {
    const translations = useTranslations();
    const [activeIndex, setActiveIndex] = useState(0);
    const selectedItem = items[activeIndex];
    const carouselRef = useRef<any>();

    const handlePrev = () => {
        if (carouselRef?.current) {
            carouselRef.current.gotoIndex(activeIndex === (items.length - 1) ? 0 : (activeIndex + 1));
        }
    }

    const handleNext = () => {
        if (carouselRef?.current) {
            carouselRef.current.gotoIndex(activeIndex === 0 ? (items.length - 1) : (activeIndex - 1));
        }
    }

    return (
        <BuildingWrapper ref={ref}>
            <PageHeader title={translations.buildings[BuildingType.KSB_TEAM].name} />
     
            <Background>
                <TextBox>
                    <TextBoxTitle>
                        {selectedItem.title}
                    </TextBoxTitle>

                    <p>
                        {selectedItem.content}
                    </p>

                    <img src={selectedItem.img} alt="" />

                    <Anchor secondary href={selectedItem.href}>
                        Mehr erfahren
                    </Anchor>
                </TextBox>
                <CarouselWrapper>
                    <StyledCarousel
                        height="10%"
                        width="600px"
                        yOrigin={100}
                        xOrigin={550}
                        yRadius={50}
                        // xRadius={350}
                        autoPlay={false}
                        onChange={index => setActiveIndex(index)}
                        innerRef={(el: any) => carouselRef.current = el}
                    >
                        {items.map((item, index) => {
                            const isLeft = (activeIndex + 1 === index || (activeIndex + 1 > items.length && index === 0));
                            const isRight = (activeIndex - 1 === index || (activeIndex - 1 < 0 && index === items.length - 1));

                            return (
                                <div
                                    key={item.img}
                                    style={{
                                        width: item.width,
                                        height: item.height,
                                        transition: 'margin 500ms, transform 200ms, left 200ms, top 200ms',
                                        marginTop: index === activeIndex ? '30px' : 0,
                                        left: isLeft ? '-30px' : isRight ? '30px' : 0,
                                        top: isLeft || isRight ? '20px' : 0,
                                    }}
                                >
                                    <CarouselImage alt="" src={item.img} />
                                    <OverlayImage alt="" src={item.overlay} active={index === activeIndex} />
                                </div>
                            )
                        })}
                    </StyledCarousel>

                    <CarouselButton direction="prev" onClick={handlePrev}>
                        <ArrowRight />
                    </CarouselButton>

                    <CarouselButton direction="next" onClick={handleNext}>
                        <ArrowRight />
                    </CarouselButton>
                </CarouselWrapper>
            </Background>
        </BuildingWrapper>
    )
})

export default KSBTeam;
