import { BuildingType } from '../buildings';
import administrationConfig from './administration';
import apartmentConfig from './apartment';
import factoryConfig, {factoryHeatingConfig, factoryProcessesConfig, factoryProductionConfig, factorySprinklerCenterConfig} from './factory';
import hospitalConfig from './hospital';
import houseConfig from './house';
import industryConfig, {industryLabConfig, industryProductionConfig, industryTestcenterConfig, industryWaterConfig, industrySprinklerCenterConfig} from './industry';
import ksbCenterConfig from './ksb-center';
import ksbTeamConfig from './ksb-team';
import schoolConfig from './school';
import skyscraperConfig from './skyscraper';
import hotelConfig from './hotel';

export const buildings = {
    [BuildingType.HOUSE]: houseConfig,
    [BuildingType.APARTMENT]: apartmentConfig,
    [BuildingType.HOSPITAL]: hospitalConfig,
    [BuildingType.SCHOOL]: schoolConfig,
    [BuildingType.ADMINISTRATION]: administrationConfig,
    [BuildingType.SKYSCRAPER]: skyscraperConfig,
    [BuildingType.INDUSTRY]: industryConfig,
    [BuildingType.INDUSTRY_WATER]: industryWaterConfig,
    [BuildingType.INDUSTRY_TESTCENTER]: industryTestcenterConfig,
    [BuildingType.INDUSTRY_PRODUCTION]: industryProductionConfig,
    [BuildingType.INDUSTRY_LAB]: industryLabConfig,
    [BuildingType.INDUSTRY_SPRINKLER_CENTER]: industrySprinklerCenterConfig,
    [BuildingType.FACTORY]: factoryConfig,
    [BuildingType.FACTORY_HEATING]: factoryHeatingConfig,
    [BuildingType.FACTORY_PRODUCTION]: factoryProductionConfig,
    [BuildingType.FACTORY_PROCESSES]: factoryProcessesConfig,
    [BuildingType.FACTORY_SPRINKLER_CENTER]: factorySprinklerCenterConfig,
    [BuildingType.KSB_CENTER]: ksbCenterConfig,
    [BuildingType.HOTEL]: hotelConfig,
    [BuildingType.KSB_TEAM]: ksbTeamConfig,
}