import styled from 'styled-components/macro';
import { rgba } from 'polished';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { createRef, useEffect } from 'react';
import { PropsList } from 'react-zoom-pan-pinch/dist/store/interfaces/propsInterface';
import { ReactComponent as ScaleBg } from '../../assets/scale-bg.svg';

type PinchPanImgProps = {
    src: string;
    forwardedRef?: React.RefObject<HTMLImageElement>;
    onLoad?(): void;
}

type PinchPanProps = {
    children: React.ReactNode;
    staticChildren?: React.ReactNode;
}

const ScaleFactor = styled.div`
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    background: ${props => rgba(props.theme.colors.primaryLight, .6)};
    padding: 5px;

    span {
        display: block;
        padding: 1px 4px 1px 9px;

        @media ${props => props.theme.screens.lg} {
            padding: 3px 8px 3px 12px;
        }
    }

    @media ${props => props.theme.screens.lg} {
        font-size: 18px;
    }
`

const ScaleBgWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    color: #fff;

    svg {
        width: 8px;
        height: 8px;
    }
`

export const StyledImg = styled.img`
    display: block;
    max-width: 100vw;
    max-height: calc(100vh - 75px);
`

export const PinchPanImg = ({
    src,
    forwardedRef = createRef<HTMLImageElement>(),
    onLoad = () => {}
}: PinchPanImgProps) => {

    useEffect(() => {
        const img = forwardedRef?.current;

        img?.addEventListener('load', onLoad);
       
        return () => {
            img?.removeEventListener('load', onLoad)
        }
    }, [forwardedRef, onLoad])

    return (
        <TransformComponent>
            <StyledImg ref={forwardedRef} src={src} />
        </TransformComponent>
    )
}

export const PinchPan = (props: PinchPanProps) => {
    return (
        <TransformWrapper
            pan={{
                paddingSize: 0
            }}
            scalePadding={{
                disabled: true
            }}
            zoomIn={{
                step: 5
            }}
            zoomOut={{
                step: 5
            }}
            doubleClick={{
                step: 10
            }}
            wheel={{
                step: 10
            }}
            options={{
                maxScale: 2
            }}
        >
            {(transformProps: PropsList) => {
                const content = typeof props.children === 'function' ? props.children(transformProps) : props.children
                const scale = transformProps.scale ? Math.round(100 * transformProps.scale) : 100

                return (
                    <>
                        <ScaleFactor>
                            <ScaleBgWrapper><ScaleBg /><ScaleBg style={{transform: 'scaleX(-1)'}} /></ScaleBgWrapper>
                            <span>{scale} %</span>
                            <ScaleBgWrapper><ScaleBg style={{transform: 'rotate(-180deg) scaleX(-1)'}} /><ScaleBg style={{transform: 'rotate(-180deg)'}} /></ScaleBgWrapper>
                        </ScaleFactor>
                        {content}
                    </>
                )
            }}
        </TransformWrapper>
    )
}

