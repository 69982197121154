import styled from 'styled-components/macro';
import { CSSTransition } from 'react-transition-group';
import BuildingDetails from './building-details';
import { createRef, useCallback, useEffect } from 'react';
import { Route } from "react-router-dom";
import cityBg from '../assets/city.jpg';
import Buildings from './buildings';
import KSBCenter from './ksb-center';
import KSBTeam from './ksb-team';
import { cityHotspots, subBuildingRoutes } from '../config/city';
import { setAppDimensions } from './store/actions';
import { useStore } from './store';
import { getBuildingRoute } from './utils/router';
import { SpecialRoutes } from '../config/app';
import useTranslations from './utils/language-provider';
import BuildingWrapper from './building-wrapper';
import ContactFlag from './ui/contact-flag';
import { buildings } from '../config/building-config';
import { BuildingArea } from '../interfaces/buildings';

const CityWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
`

const City = () => {
    const {dispatch} = useStore();
    const imgRef = createRef<HTMLImageElement>();
    const ksbCenterRef = createRef<HTMLDivElement>();
    const ksbTeamRef = createRef<HTMLDivElement>();
    const translations = useTranslations();

    const setCurrentBgSize = useCallback(() => {
        const img = imgRef?.current;

        if (img) {
            dispatch(setAppDimensions({
                width: img.offsetWidth,
                height: img.offsetHeight
            }))
        }
    }, [dispatch, imgRef])

    const handleImgLoad = () => {
        setCurrentBgSize();
    }

    useEffect(() => {
        window.addEventListener('resize', setCurrentBgSize);

        return () => {
            window.removeEventListener('resize', setCurrentBgSize);
        }
    }, [setCurrentBgSize])

    return (
        <CityWrapper>
            <Route exact path="/">
                {({ match }) => (
                    <Buildings
                        src={cityBg}
                        pageBackBtn={false}
                        pageTitle={translations.appTitle}
                        pageSubTitle={translations.appSubTitle}
                        visible={match != null}
                        imgRef={imgRef}
                        onImgLoad={handleImgLoad}
                        hotspots={cityHotspots}
                    />
                )}
            </Route>

            {[...cityHotspots, ...subBuildingRoutes].map(hotspotConfig => {
                const itemRef = createRef<HTMLDivElement>();

                return (
                    <Route key={hotspotConfig.building} path={getBuildingRoute(hotspotConfig.building)}>
                        {({ match }) => {
                            const buildingConfig = buildings[hotspotConfig.building] as BuildingArea;

                            return (
                                <CSSTransition
                                    in={match != null}
                                    timeout={500}
                                    classNames="page-fade"
                                    unmountOnExit
                                    nodeRef={itemRef}
                                >
                                    {hotspotConfig.subBuildings ?
                                        <BuildingWrapper ref={itemRef}>
                                            <Buildings
                                                transitionClassName="page-zoom-in"
                                                src={buildings[hotspotConfig.building].img}
                                                pageTitle={translations.buildings[hotspotConfig.building].name}
                                                visible={match != null}
                                                hotspots={buildingConfig?.hotspots}
                                                techSectors={buildingConfig?.techSectors}
                                                type={hotspotConfig.building}
                                            />
                                        </BuildingWrapper>
                                    :
                                        <BuildingDetails
                                            ref={itemRef}
                                            type={hotspotConfig.building}
                                        />
                                    }
                                </CSSTransition>
                            )
                        }}
                    </Route>
                )
            })}

            <Route path={`/${SpecialRoutes.KSB_CENTER}`}>
                {({ match }) => (
                    <CSSTransition
                        in={match != null}
                        timeout={500}
                        classNames="page-fade"
                        unmountOnExit
                        nodeRef={ksbCenterRef}
                    >
                        <KSBCenter ref={ksbCenterRef} />
                    </CSSTransition>
                )}
            </Route>

            <Route path={`/${SpecialRoutes.KSB_TEAM}`}>
                {({ match }) => (
                    <CSSTransition
                        in={match != null}
                        timeout={500}
                        classNames="page-fade"
                        unmountOnExit
                        nodeRef={ksbTeamRef}
                    >
                        <KSBTeam ref={ksbTeamRef} />
                    </CSSTransition>
                )}
            </Route>

            <ContactFlag />
        </CityWrapper>
    )
}

export default City;
