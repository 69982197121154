export enum BuildingType {
    HOUSE = 'house',
    APARTMENT = 'apartment',
    HOSPITAL = 'hospital',
    SCHOOL = 'school',
    ADMINISTRATION = 'administration',
    SKYSCRAPER = 'skyscraper',
    FACTORY = 'factory',
    FACTORY_HEATING = 'factory-heating',
    FACTORY_PRODUCTION = 'factory-production',
    FACTORY_SPRINKLER_CENTER = 'factory-sprinkler-center',
    FACTORY_PROCESSES = 'factory-processes',
    INDUSTRY = 'industry',
    INDUSTRY_LAB = 'industry-lab',
    INDUSTRY_PRODUCTION = 'industry-production',
    INDUSTRY_WATER = 'industry-water',
    INDUSTRY_TESTCENTER = 'industry-testcenter',
    INDUSTRY_SPRINKLER_CENTER = 'industry-sprinkler-center',
    KSB_CENTER = 'ksbCenter',
    HOTEL = 'hotel',
    KSB_TEAM = 'ksbTeam'
}
