import { INITIAL_IMG_SIZE } from '../../config/app';

type GetPositionProps = {
    x: number;
    y: number;
    imgWidth: number;
    imgHeight: number;
    imgScale?: number;
    imgOffsetX?: number;
    imgOffsetY?: number;
}

export const toPx = (value: string | number) => `${value}px`; 

export const getOffsetPosition = ({
    x,
    y,
    imgWidth,
    imgHeight,
    imgScale = 1,
    imgOffsetX = 0,
    imgOffsetY = 0
}: GetPositionProps) => {
    const left = ((x * imgWidth) / INITIAL_IMG_SIZE.width) * imgScale + imgOffsetX;
    const top = ((y * imgHeight) / INITIAL_IMG_SIZE.height) * imgScale + imgOffsetY;

    return {
        left: toPx(left),
        top: toPx(top),
    }
}