import styled from 'styled-components/macro';
import teaserImg from '../../assets/buildings/ksb-center/teaser-etalinepro.png';
import teaserImgSmall from '../../assets/buildings/ksb-center/teaser-etalinepro-small.jpg';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import { useResponsive } from '../utils/responsive';

const Perspective = styled.div`
    perspective: 500px;
`

const Teaser = styled.div`
    overflow: hidden;
    position: relative;
    width: 100%;

    @media ${props => props.theme.screens.lg} {
        transform: rotateY(7deg) skewY(-2.9deg);
        transform-origin: left;
    }
`


const TeaserImg = styled.img`
    width: 100%;
`

const Text = styled.div`
    background-color: rgb(255 255 255 / 90%);
    padding: 7px;
    position: absolute;
    text-transform: uppercase;
    top: 40%;
    left: 10px;
    width: 90%;

    @media ${props => props.theme.screens.sm} {
        padding: 10px;
        width: 50%;
    }

    @media ${props => props.theme.screens.md} {
        left: 30px;
        top: 42%;
    }
`;

const Link = styled.a`
    color: #060606;
    display: block;
    font-size: 20px;
    font-weight: regular;
    padding-left: 20px;
    text-decoration: none;
    text-transform: none;


    @media ${props => props.theme.screens.sm} {
        font-size: 22px;
    }

    svg {
        height: 12px;
        color: ${props => props.theme.colors.secondary};
        position: absolute;
        top: 14px;
        left: 15px;

        @media ${props => props.theme.screens.sm} {
            top: 19px;
        }
    }
`

const BonusCampaign = () => {
    const {large} = useResponsive();

    return (
        <Perspective>
            <Teaser>


                <TeaserImg src={large ? teaserImg : teaserImgSmall} />

                <Text>
                    <Link href="https://www.ksb.com/de-de/anwendungen/neue-aera" target="_blank">
                        <ArrowRight /> Alles über die neue Inlinepumpe EtaLine Pro erfahren
                    </Link>
                </Text>

            </Teaser>
        </Perspective>
    )
}

export default BonusCampaign;
