import styled from "styled-components/macro";
import { TechSector } from "../../config/tech-sector";
import { ReactComponent as IconWater } from '../../assets/icon-water.svg';
import { ReactComponent as IconWasteWater } from '../../assets/icon-waste-water.svg';
import { ReactComponent as IconHeating } from '../../assets/icon-heating.svg';
import { ReactComponent as IconFireProtection } from '../../assets/icon-fire-protection.svg';
import { getTechSectorBackground } from "../utils/colors";
import { forwardRef } from "react";


type TechSectorIconProps = {
    sectorType: TechSector;
    inactive?: boolean;
    style?: React.CSSProperties;
    className?: string;
    onHover?: [()=> void, () => void];
}

const IconMapping = {
    [TechSector.FIRE_PROTECTION]: <IconFireProtection />,
    [TechSector.WASTE_WATER]: <IconWasteWater />,
    [TechSector.WATER]: <IconWater />,
    [TechSector.HEATING]: <IconHeating />,
}

const IconWrapper = styled.span<TechSectorIconProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 22px;
    color: #fff;
    margin: 0 3px;
    background: ${({theme, sectorType, inactive}) => getTechSectorBackground(theme, sectorType, inactive)};
    border-radius: 100%;
    transition: all 300ms;
    border: 1px solid #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .3);
    transform-origin: center;

    &:not(.active):hover {
        z-index: 15;
        ${props => !props.inactive ? 'transform: scale(1.2);' : ''}
    }

    &.active {
        box-shadow: 0 5px 10px rgba(0, 0, 0, .3);
    }

    svg {
        height: 50%;
    }

    @media ${props => props.theme.screens.lg} {
        height: 44px;
        width: 44px;
        border-width: 3px;
    }
`

const TechSectorIcon = forwardRef<HTMLSpanElement, TechSectorIconProps>(({
    sectorType,
    onHover = [() => {}, () => {}],
    className,
    ...props
}, ref) => {

    return (
        <IconWrapper
            ref={ref}
            className={className}
            sectorType={sectorType}
            onMouseEnter={() => onHover[0]()}
            onMouseLeave={() => onHover[1]()}
            {...props}
        >
            {IconMapping[sectorType]}
        </IconWrapper>
    )
})

export default TechSectorIcon;