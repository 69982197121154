import imgOutside from '../../assets/buildings/hospital/outside.jpg';
import imgWasteWater from '../../assets/buildings/hospital/waste-water.jpg';
import imgWater from '../../assets/buildings/hospital/water.jpg';
import imgHeating from "../../assets/buildings/hospital/heating.jpg";
import imgFireProtection from '../../assets/buildings/hospital/fire-protection.jpg';
import { Building } from '../../interfaces/buildings';
import { ProductType } from '../products';
import { TechSector } from '../tech-sector';

const hospitalConfig: Building = {
    techSectors: [
        {
            type: TechSector.WATER,
            img: imgWater,
            products: [
                {
                    position: {x: 495, y: 819},
                    type: ProductType.SISTO_16_TWA
                },
                {
                    position: {x: 599, y: 657},
                    type: ProductType.BOAX_S_SF
                },
                {
                    position: {x: 990, y: 787},
                    type: ProductType.BOA_COMPACT_EKB
                },
                {
                    position: {x: 942, y: 602},
                    type: ProductType.MOVITEC
                },
                {
                    position: {x: 692, y: 817},
                    type: ProductType.KSB_DELTA_PRIMO_SVP
                },
                {
                    position: {x: 1773, y: 857},
                    type: ProductType.UPA
                }
            ]
        },
        {
            type: TechSector.HEATING,
            img: imgHeating,
            products: [
                {
                    position: {x: 172, y: 525},
                    type: ProductType.BOAX_S_SF
                },
                {
                    position: {x: 310, y: 560},
                    type: ProductType.ETANORM
                },
                {
                    position: {x: 245, y: 500},
                    type: ProductType.SERIE_2000
                },
                {
                    position: {x: 426, y: 517},
                    type: ProductType.BOA_CONTROL_IMS
                },
                {
                    position: {x: 450, y: 510},
                    type: ProductType.BOATRONIC_MS_420
                },
                {
                    position: {x: 535, y: 460},
                    type: ProductType.SISTO_16_RGA
                },
                {
                    position: {x: 800, y: 465},
                    type: ProductType.BOA_CONTROL_DPR
                },
                {
                    position: {x: 865, y: 492},
                    type: ProductType.CALIO_Z
                },
                {
                    position: {x: 970, y: 430},
                    type: ProductType.BOA_CONTROL_SBV
                },
                {
                    position: {x: 1050, y: 507},
                    type: ProductType.BOA_RVK
                },
                {
                    position: {x: 1161, y: 473},
                    type: ProductType.CALIO
                },
                {
                    position: {x: 1245, y: 485},
                    type: ProductType.ETALINE_Z
                },
            ]
        },
        {
            type: TechSector.WASTE_WATER,
            img: imgWasteWater,
            products: [
                {
                    position: {x: 127, y: 950},
                    type: ProductType.AMAREX
                },
                {
                    position: {x: 1686, y: 617},
                    type: ProductType.COMPACTA
                },
                {
                    position: {x: 1135, y: 733},
                    type: ProductType.AMA_DRAINER_BOX_UNTERFLUR
                },
                {
                    position: {x: 1457, y: 761},
                    type: ProductType.AMA_DRAINER_4_5
                },
                {
                    position: {x: 1531, y: 600},
                    type: ProductType.LEVELCONTROL
                }
            ]
        },
        {
            type: TechSector.FIRE_PROTECTION,
            img: imgFireProtection,
            products: [
                {
                    position: {x: 1094, y: 902},
                    type: ProductType.ETANORM_FXV
                },
                {
                    position: {x: 1422, y: 850},
                    type: ProductType.HYADUO_2_D_FL_COMPACT
                },
            ]
        }
    ],
    img: imgOutside
}

export default hospitalConfig;
