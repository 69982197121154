import { TechSector } from "./tech-sector";

const theme = {
    font: 'Frutiger,helvetica,roboto,arial,sans-serif',
    colors: {
      primaryDark: '#09213a',
      primary: '#00569d',
      primaryLight: '#4d89ba',
      primaryLighter: '#a0d2f3',
      secondary: '#eb5b25',
      secondaryLight: '#f18c66',
      gray1: '#cccccc',
      gray2: '#bbbbbb',
      gray3: '#575756',
      sectors: {
        [TechSector.WATER]: '#0f6cb5',
        [TechSector.HEATING]: ['#e41428', '#66bdcd'],
        [TechSector.WASTE_WATER]: '#ebd900',
        [TechSector.FIRE_PROTECTION]: '#741329',
      },
    },
    screens: {
        sm: '(min-width: 640px)',
        md: '(min-width: 768px)',
        lg: '(min-width: 1024px)',
    }
}

export default theme;