import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
    text-decoration: none;
`

type LinkButtonProps = ButtonProps & {
    to: string;
}

type StyledButtonProps = {
    secondary?: boolean;
    align?: 'left' | 'center';
}

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & StyledButtonProps;
type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & StyledButtonProps;

const StyledButton = styled.button<StyledButtonProps>`
    display: flex;
    justify-content: ${props => props?.align === 'left' ? 'flex-start' : 'center'};
    align-items: center;
    font-family: ${props => props.theme.font};
    font-weight: 400;
    letter-spacing: 0.01em;
    font-size: 16px;
    line-height: 1.425;
    font-weight: 400;
    text-align: ${props => props?.align === 'left' ? 'left' : 'center'};
    white-space: normal;
    margin: 0;
    padding: 12px 16px;
    border-radius: 0;
    cursor: pointer;
    transition: background 100ms ease-in;
    vertical-align: middle;
    color: #fff;
    background-color: ${props => props.secondary ? props.theme.colors.secondary : props.theme.colors.primary};
    box-shadow: none;
    border: none;
    text-decoration: none;

    &:hover {
        background-color: ${props => props.secondary ? props.theme.colors.secondaryLight : props.theme.colors.primaryLight};
    }

    svg {
        flex-shrink: 0;
        height: 20px;
        margin-right: 10px;
    }
`

const Button = (props: ButtonProps) => (
    <StyledButton className={props.className} {...props} />
)

export const Anchor = (props: AnchorProps) => {
    return <StyledButton as="a" {...props} />
}

export const LinkButton = ({
    to,
    ...props
}: LinkButtonProps) => (
    <StyledLink to={to}>
        <Button {...props} />
    </StyledLink>
)