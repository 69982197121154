import { useMediaQuery } from 'react-responsive';
import theme from '../../config/theme';

export const useResponsive = () => {
    const small = useMediaQuery({ query: theme.screens.sm });
    const middle = useMediaQuery({ query: theme.screens.md });
    const large = useMediaQuery({ query: theme.screens.lg });

    return {small, middle, large};
}
