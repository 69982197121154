import { createRef, useState } from "react";
import styled from 'styled-components/macro';
import classNames from 'classnames';
import { PinchPanImg, PinchPan } from "./utils/pinch-pan-helpers";
import { PropsList } from "react-zoom-pan-pinch/dist/store/interfaces/propsInterface";
import HotspotFlag from "./ui/hotspot-flag";
import { getOffsetPosition } from "./utils/position";
import { useStore } from "./store";
import { useHistory, useParams } from "react-router-dom";
import { HotspotList, HotspotConfig } from "../config/city";
import { CSSTransition } from "react-transition-group";
import PageHeader, {PAGE_HAEDER_HEIGHT} from './ui/page-header';
import { BuildingType } from "../config/buildings";
import BuildingNavigation from "./ui/building-navigation";
import { RouteParams } from "../interfaces/router";
import { BuildingTechSectors } from "../interfaces/buildings";

type BuildingsProps = {
    visible: boolean;
    pageBackBtn?: boolean;
    pageTitle?: string;
    pageSubTitle?: string;
    imgRef?: React.RefObject<HTMLImageElement>;
    onImgLoad?(): void;
    src: string;
    hotspots?: HotspotList;
    techSectors?: BuildingTechSectors[];
    transitionClassName?: string;
    type?: BuildingType
}

const StyledHotspotFlag = styled(HotspotFlag)`
    --animate-duration: 700ms;
    opacity: 0;

    &.active {
        opacity: 1;
    }
`;

const ZoomContainer = styled.div`
    position: relative;
    overflow: hidden;
`

const Buildings = ({
    visible,
    imgRef,
    src,
    pageTitle,
    pageSubTitle,
    hotspots,
    techSectors,
    transitionClassName = 'page-zoom',
    pageBackBtn = true,
    onImgLoad = () => {},
    type
}: BuildingsProps) => {
    const {state} = useStore();
    const history = useHistory();
    const [hotspotsActive, setHotspotsActive] = useState(false);
    const containerRef = createRef<HTMLDivElement>();
    const {techSector: techSectorParam = ''} = useParams<RouteParams>();

    const hasSubbildings = techSectors?.length;
    let spots = hotspots;
    let img = src;

    if (hasSubbildings) {
        const sectorDetails = techSectors?.find(sector => sector.type === techSectorParam);

        if (sectorDetails) {
            spots = sectorDetails.buildings;
            img = sectorDetails.img || '';
        }
    }

    const handleImgLoad = () => {
        onImgLoad();
        setHotspotsActive(true);
    }

    const openBuilding = (path: string, coords: string) => {
        if (containerRef.current) {
            containerRef.current.style.transformOrigin = coords
        }
        history.push(`/${path}`);
    }

    const handleComeback = () => {
        setHotspotsActive(true);
    }

    const handleHotspotClick = (config: HotspotConfig, pos: {left: string, top: string}) => {
        if (config.type !== 'inactive') {
            openBuilding(config.link || config.building, `${pos.left} ${pos.top}`)
        }
    }

    return (
        <div>
            {pageTitle ?
                <PageHeader backBtn={pageBackBtn} title={pageTitle} subTitle={pageSubTitle} />
            :
                <div style={{height: PAGE_HAEDER_HEIGHT}} />
            }

            <ZoomContainer>
                {(type && hasSubbildings) &&
                    <BuildingNavigation buildingType={type} />
                }

                <CSSTransition
                    in={visible}
                    timeout={500}
                    classNames={transitionClassName}
                    nodeRef={containerRef}
                    onEntered={() => handleComeback()}
                    onExited={() => setHotspotsActive(false)}
                >
                    <div ref={containerRef}>
                        <PinchPan>
                            {({ positionX: imgOffsetX, positionY: imgOffsetY, scale: imgScale }: PropsList) => {

                                return (
                                    <>
                                        <PinchPanImg forwardedRef={imgRef} src={img} onLoad={handleImgLoad} />

                                        {hotspotsActive && spots?.map((hotspotConfig, index) => {

                                            const pos = getOffsetPosition({
                                                ...hotspotConfig.coordinates,
                                                imgWidth: state.appDimensions.width,
                                                imgHeight: state.appDimensions.height,
                                                imgScale, imgOffsetX, imgOffsetY
                                            })

                                            if (hotspotConfig.component) {
                                                return (
                                                    <hotspotConfig.component
                                                        key={hotspotConfig.building}
                                                        style={{
                                                            ...pos,
                                                            animationDelay: `${index * 100}ms`,
                                                        }}
                                                        className={classNames('animated', {
                                                            bounceInDown: hotspotsActive,
                                                            active: hotspotsActive,
                                                        })}
                                                    />
                                                )
                                            }

                                            return (
                                                <StyledHotspotFlag
                                                    config={hotspotConfig}
                                                    key={hotspotConfig.building}
                                                    style={{
                                                        ...pos,
                                                        animationDelay: `${index * 100}ms`,
                                                    }}
                                                    className={classNames('animated', {
                                                        bounceInDown: hotspotsActive,
                                                        active: hotspotsActive,
                                                    })}
                                                    onClickTooltip={() => handleHotspotClick(hotspotConfig, pos)}
                                                />
                                            )
                                        })}
                                    </>
                                )
                            }}
                        </PinchPan>
                    </div>
                </CSSTransition>
            </ZoomContainer>
        </div>
    )
}

export default Buildings;
