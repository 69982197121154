import { BuildingType } from '../buildings';
import imgOutside from '../../assets/buildings/industry/outside.jpg';
import imgWater from '../../assets/buildings/industry/water.jpg';
import imgProduction from '../../assets/buildings/industry/production.jpg';
import imgLab from '../../assets/buildings/industry/lab.jpg';
import imgFireProtection from '../../assets/buildings/industry/fire-protection.jpg';
import imgSprinklerCenter from '../../assets/buildings/industry/sprinkler-center.jpg';
import { BuildingArea, SubBuilding } from '../../interfaces/buildings';
import { ProductType } from '../products';
import { TechSector } from '../tech-sector';

const industryConfig: BuildingArea = {
    techSectors: [
        {
            type: TechSector.WATER,
            img: imgOutside,
            buildings: [
                {
                    coordinates: {x: 230, y: 577},
                    building: BuildingType.INDUSTRY_PRODUCTION,
                    link: `${BuildingType.INDUSTRY_PRODUCTION}/${TechSector.WATER}`,
                    showSector: false
                },
                {
                    coordinates: {x: 1400, y: 484},
                    building: BuildingType.INDUSTRY_TESTCENTER,
                    link: `${BuildingType.INDUSTRY_TESTCENTER}/${TechSector.WATER}`,
                    type: 'inactive',
                    showSector: false
                },
                {
                    coordinates: {x: 700, y: 424},
                    building: BuildingType.INDUSTRY_WATER,
                    link: `${BuildingType.INDUSTRY_WATER}/${TechSector.WATER}`,
                    showSector: false
                },
                {
                    coordinates: {x: 1007, y: 526},
                    building: BuildingType.INDUSTRY_LAB,
                    link: `${BuildingType.INDUSTRY_LAB}/${TechSector.WATER}`,
                    showSector: false
                },
            ]
        },
        {
            type: TechSector.FIRE_PROTECTION,
            img: imgFireProtection,
            buildings: [
                {
                    coordinates: {x: 1462, y: 343},
                    building: BuildingType.INDUSTRY_SPRINKLER_CENTER,
                    link: `${BuildingType.INDUSTRY_SPRINKLER_CENTER}/${TechSector.FIRE_PROTECTION}`,
                    showSector: false
                },
            ]
        }
    ],
    img: imgOutside
}

export const industryLabConfig: SubBuilding = {
    parentBuilding: BuildingType.INDUSTRY,
    img: imgLab,
    techSectors: [
        {
            type: TechSector.WATER,
        }
    ],
    products: [
        {
            position: {x: 338, y: 608},
            type: ProductType.KSB_SAFETY_BOOST
        },
    ]
}

export const industryWaterConfig: SubBuilding = {
    parentBuilding: BuildingType.INDUSTRY,
    img: imgWater,
    techSectors: [
        {
            type: TechSector.WATER,
        }
    ],
    products: [
        {
            position: {x: 878, y: 590},
            type: ProductType.KSB_DELTA_PRIMO_SVP
        },
    ]
}

export const industryProductionConfig: SubBuilding = {
    parentBuilding: BuildingType.INDUSTRY,
    img: imgProduction,
    techSectors: [
        {
            type: TechSector.WATER,
        }
    ],
    products: [
        {
            position: {x: 1515, y: 558},
            type: ProductType.ETANORM
        },
        {
            position: {x: 1682, y: 521},
            type: ProductType.BOA_COMPACT_EKB
        },
    ]
}

export const industryTestcenterConfig: SubBuilding = {
    parentBuilding: BuildingType.INDUSTRY,
    img: imgProduction,
    techSectors: [],
    products: []
}

export const industrySprinklerCenterConfig: SubBuilding = {
    parentBuilding: BuildingType.INDUSTRY,
    img: imgSprinklerCenter,
    techSectors: [
        {
            type: TechSector.FIRE_PROTECTION,
        }
    ],
    products: [
        {
            position: {x: 125, y: 605},
            type: ProductType.KSB_SAFETY_BOOST
        },
        {
            position: {x: 1191, y: 212},
            type: ProductType.ETANORM_FXV
        },
        {
            position: {x: 1432, y: 349},
            type: ProductType.HYASOLO_2_D_FL_COMPACT
        }
    ]
}

export default industryConfig;