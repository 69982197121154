import imgOutside from '../../assets/buildings/administration/outside.jpg';
import imgWasteWater from '../../assets/buildings/administration/waste-water.jpg';
import imgHeating from '../../assets/buildings/administration/heating.jpg';
import imgFireProtection from '../../assets/buildings/administration/fire-protection.jpg';
import imgWater from '../../assets/buildings/administration/water.jpg';
import { Building } from '../../interfaces/buildings';
import { ProductType } from '../products';
import { TechSector } from '../tech-sector';

const administrationConfig: Building = {
    techSectors: [
        {
            type: TechSector.HEATING,
            img: imgHeating,
            products: [
                {
                    position: {x: 194, y: 775},
                    type: ProductType.BOAX_S_SF
                },
                {
                    position: {x: 350, y: 800},
                    type: ProductType.ETANORM
                },
                {
                    position: {x: 410, y: 730},
                    type: ProductType.BOA_CVE_IMS
                },
                {
                    position: {x: 445, y: 320},
                    type: ProductType.BOA_CONTROL_PIC
                },
                {
                    position: {x: 485, y: 750},
                    type: ProductType.BOATRONIC_MS_420
                },
                {
                    position: {x: 915, y: 710},
                    type: ProductType.CALIO_Z
                },
                {
                    position: {x: 1320, y: 650},
                    type: ProductType.CALIO
                },
                {
                    position: {x: 1145, y: 650},
                    type: ProductType.BOA_CONTROL_DPR
                },
                {
                    position: {x: 1210, y: 620},
                    type: ProductType.BOA_CONTROL_SBV
                },
                {
                    position: {x: 1070, y: 660},
                    type: ProductType.BOA_RVK
                },
                {
                    position: {x: 1430, y: 670},
                    type: ProductType.ETALINE_Z
                },
            ]
        },
        {
            type: TechSector.WASTE_WATER,
            img: imgWasteWater,
            products: [
                {
                    position: {x: 409, y: 963},
                    type: ProductType.AMA_DRAINER_4_5
                },
                {
                    position: {x: 1188, y: 903},
                    type: ProductType.COMPACTA
                },
                {
                    position: {x: 1856, y: 791},
                    type: ProductType.AMA_DRAINER_BOX_UEBERFLUR
                },
                {
                    position: {x: 1361, y: 850},
                    type: ProductType.AMA_DRAINER_4_5
                },
                {
                    position: {x: 1437, y: 691},
                    type: ProductType.LEVELCONTROL
                }
            ]
        },
        {
            type: TechSector.FIRE_PROTECTION,
            img: imgFireProtection,
            products: [
                {
                    position: {x: 1077, y: 828},
                    type: ProductType.HYADUO_2_D_FL_COMPACT
                }
            ],
        },
        {
            type: TechSector.WATER,
            img: imgWater,
            products: [
                {
                    position: {x: 701, y: 798},
                    type: ProductType.SISTO_16
                },
                {
                    position: {x: 789, y: 763},
                    type: ProductType.KSB_SAFETY_BOOST
                },
                {
                    position: {x: 238, y: 1004},
                    type: ProductType.UPA
                },
                {
                    position: {x: 1272, y: 714},
                    type: ProductType.BOA_COMPACT_EKB
                },
                {
                    position: {x: 882, y: 779},
                    type: ProductType.SISTO_16_TWA
                },
                {
                    position: {x: 995, y: 708},
                    type: ProductType.KSB_DELTA_PRIMO_SVP
                },
            ]
        }
    ],
    img: imgOutside
}

export default administrationConfig;
