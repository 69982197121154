import imgOutside from '../../assets/buildings/house/outside.jpg';
import imgWasteWater from '../../assets/buildings/house/waste-water.jpg';
import imgHeating from '../../assets/buildings/house/heating.jpg';
import imgWater from '../../assets/buildings/house/water.jpg';
import { Building } from '../../interfaces/buildings';
import { TechSector } from '../tech-sector';
import { ProductType } from '../products';

const houseConfig: Building = {
    techSectors: [
        {
            type: TechSector.WATER,
            img: imgWater,
            products: [
                {
                    position: {x: 126, y: 642},
                    type: ProductType.MULTI_ECO
                },
                {
                    position: {x: 307, y: 483},
                    type: ProductType.FILTRA
                },
                {
                    position: {x: 876, y: 712},
                    type: ProductType.HYA_RAIN_N
                },
                {
                    position: {x: 1009, y: 762},
                    type: ProductType.DELTA_BASIC_COMPACT_MVP
                },
                {
                    position: {x: 1760, y: 900},
                    type: ProductType.IXO_PRO
                },
            ]
        },
        {
            type: TechSector.HEATING,
            img: imgHeating,
            products: [
                {
                    position: {x: 1100, y: 719},
                    type: ProductType.SISTO_16
                },
                {
                    position: {x: 1195, y: 712},
                    type: ProductType.CALIO_THERM_S
                },
                {
                    position: {x: 1305, y: 711},
                    type: ProductType.CALIO_S
                },
                {
                    position: {x: 1564, y: 619},
                    type: ProductType.BOA_CONTROL_SBV
                },
                {
                    position: {x: 1346, y: 489},
                    type: ProductType.BOA_CONTROL_SBV
                },
            ]
        },
        {
            type: TechSector.WASTE_WATER,
            img: imgWasteWater,
            products: [
                {
                    position: {x: 759, y: 888},
                    type: ProductType.AMA_DRAINER_3
                },
                {
                    position: {x: 1412, y: 821},
                    type: ProductType.MINI_COMPACTA
                },
                {
                    position: {x: 1231, y: 817},
                    type: ProductType.AMA_DRAINER_BOX_UNTERFLUR
                },
                {
                    position: {x: 1783, y: 760},
                    type: ProductType.CK_800
                },
                {
                    position: {x: 1564, y: 685},
                    type: ProductType.LEVELCONTROL
                }
            ]
        }
    ],
    img: imgOutside
}

export default houseConfig;
