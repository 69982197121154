import styled from "styled-components/macro";
import classNames from 'classnames';
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import TechSectorButton from './tech-sector-icon';
import { formatBuildingRoute } from "../utils/router";
import useTranslations from "../utils/language-provider";
import Tooltip from './tooltip';
import { buildings } from "../../config/building-config";
import { HotspotConfig } from "../../config/city";
import { SectorListPositions } from "../../interfaces/buildings";

export type TooltipType = 'inactive' | 'secondary';
export type ArrowAlignType = 'center' | 'left';
export type LabelStyle = 'primary' | 'secondary';
export type LabelText = 'comingSoonBuilding' | 'newBuilding';

type HotspotFlagProps = {
    style?: React.CSSProperties;
    className?: string;
    onClickTooltip?(): void;
    config: HotspotConfig;
}

type HotspotFlagElementProps = {
    arrowAlign: ArrowAlignType; 
}

type HotspotWrapperProps = {
    type?: TooltipType;
    arrowAlign?: ArrowAlignType; 
}

export const HotspotFlagElement = styled.div<HotspotFlagElementProps>`
    position: relative;
    text-align: center;
    
    .inner {
        position: relative;
        z-index: 2;
        height: 30px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        color: #fff;
        background: ${props => props.theme.colors.primary};
        border-radius: 3px;
        font-size: 12px;
        font-weight: bold;
        overflow: hidden;
        transition: all 100ms ease-in;
        box-shadow: 0 5px 10px rgba(0,0,0,.6);

        @media ${props => props.theme.screens.lg} {
            font-size: 20px;
            padding: 0 18px;
            height: 50px;
        }
    }

    &::after,
    &::before {
        content: "";
        position: absolute;
        transition: all 100ms ease-in;
    }

    &::before {
        width: 15px;
        height: 12px;
        bottom: -7px;
        left: ${props => props.arrowAlign === 'center' ? '50%' : '18px'};
        margin-left: ${props => props.arrowAlign === 'center' ? '-15px' : '-1px'};
        transform: rotate(90deg) skew(45deg, 0deg);
        box-shadow: 0 5px 10px rgba(0, 0, 0, .6);

        @media ${props => props.theme.screens.lg} {
            bottom: -14px;
            width: 29px;
            height: 26px;
        }
    }

    &::after {
        z-index: 3;
        bottom: -15px;
        left: ${props => props.arrowAlign === 'center' ? '50%' : '18px'};
        margin-left: ${props => props.arrowAlign === 'center' ? '-14px' : '0'};
        border-left: 15px solid ${props => props.theme.colors.primary};
        border-bottom: 15px solid transparent;

        @media ${props => props.theme.screens.lg} {
            bottom: -29px;
            border-left-width: 29px;
            border-bottom-width: 29px;
        }
    }
`

const TechSectorList = styled.div<{sectorPosition: SectorListPositions}>`
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: ${props => props.sectorPosition === 'top' ? '-30px' : '30px'};
    padding: 0 0 20px;

    @media ${props => props.theme.screens.lg} {
        top: ${props => props.sectorPosition === 'top' ? '-60px' : '60px'};
    }
`

const StyledTechSectorButton = styled(TechSectorButton)<{sectorPosition: SectorListPositions}>`
    top: ${props => props.sectorPosition === 'top' ? '40px' : '-40px'};
    opacity: 0;
`;

export const HotspotWrapper = styled.div<HotspotWrapperProps>`
    position: absolute;
    margin-top: -45px;
    margin-left: ${props => props.arrowAlign === 'center' ? '-64px' : '-18px'};
    
    @media ${props => props.theme.screens.lg} {
        margin-top: -80px;
        margin-left: ${props => props.arrowAlign === 'center' ? '-117px' : '-18px'};
    }

    &.secondary {
        ${HotspotFlagElement} {
            .inner {
                background: ${props => props.theme.colors.secondary};
            }
    
            &::after {
                border-left-color: ${props => props.theme.colors.secondary};
            }
        }
    }

    &.inactive {
        margin-top: -62px;

        @media ${props => props.theme.screens.lg} {
            margin-top: -102px;
        }

        ${HotspotFlagElement} {
            cursor: default !important;

            .inner {
                background: ${props => props.theme.colors.gray1};
                border-radius: 0 0 3px 3px;
            }
    
            &::after {
                border-left-color: ${props => props.theme.colors.gray1};
            }
        }
    }


    &:hover {
        ${HotspotFlagElement} {
            cursor: pointer;

            .inner {
                background: ${props => props.theme.colors.primaryLight};
            }
    
            &::after {
                border-left-color: ${props => props.theme.colors.primaryLight};
            }
        }

        &.secondary {
            ${HotspotFlagElement} {
                .inner {
                    background: ${props => props.theme.colors.secondaryLight};
                }
        
                &::after {
                    border-left-color: ${props => props.theme.colors.secondaryLight};
                }
            }
        }

        &.inactive {
            ${HotspotFlagElement} {
                .inner {
                    background: ${props => props.theme.colors.gray1};
                    border-radius: 0 0 3px 3px;
                }
        
                &::after {
                    border-left-color: ${props => props.theme.colors.gray1};
                }
            }
        }

        ${StyledTechSectorButton} {
            opacity: 1;
            top: 0;
        }
    }
`

const ArrowIcon = styled.div`
    flex-shrink: 0;
    margin: 0 5px 0 0;
    text-align: center;

    @media ${props => props.theme.screens.lg} {
        margin-right: 12px;
    }

    svg {
        position: relative;
        top: 2px;
        left: 2px;
        height: 15px;

        @media ${props => props.theme.screens.lg} {
            height: 35px;
        }
    }
    
`

const Label = styled.div<{styleType: LabelStyle}>`
    display: inline-block;
    font-size: 10px;
    color: #fff;
    background: ${props => props.styleType === 'primary' ? props.theme.colors.primary : props.theme.colors.secondary};
    border-radius: 3px;
    padding: 2px 4px 3px;
    box-shadow: 0 5px 10px rgb(0, 0, 0, .6);

    @media ${props => props.theme.screens.lg} {
        font-size: 14px;
        padding: 2px 8px 3px;
    }
`

const HotspotFlag = ({
    style = {},
    className,
    onClickTooltip,
    config
}: HotspotFlagProps) => {
    const translations = useTranslations();
    const {
        type,
        labelText,
        labelStyle = 'primary',
        arrowAlign = 'left',
        building,
        showSector = true
    } = config;
    const label = translations.buildings[building].name;
    const sectors = buildings[building]?.techSectors || [];
    const sectorPosition = buildings[building]?.sectorPosition || 'top';
    const showTechSectors = (showSector === true && type !== 'inactive' && !!sectors.length);

    return (
        <HotspotWrapper style={style} arrowAlign={arrowAlign} type={type} className={classNames(className, {
            secondary: type === 'secondary',
            inactive: type === 'inactive'
        })}>
            {showTechSectors &&
                <TechSectorList sectorPosition={sectorPosition}>
                    {sectors.map((sector, index) => {
                        const btnProps = {
                            sectorType: sector.type,
                            style: {
                                transitionDelay: `${100 * index}ms`
                            }
                        } 

                        if (sector.comingSoon) {
                            return (
                                <Tooltip content={translations.city.comingSoonTechSector} key={sector.type}>
                                    {({triggerRef}) => (
                                        <StyledTechSectorButton
                                            {...btnProps}
                                            sectorPosition={sectorPosition}
                                            inactive
                                            ref={triggerRef} 
                                        />
                                    )}
                                </Tooltip>
                            )
                        }

                        return (
                            <Link key={sector.type} to={formatBuildingRoute({building, techSector: sector.type})}>
                                <Tooltip content={translations.sectors[sector.type].name}>
                                    {({triggerRef}) => (
                                        <StyledTechSectorButton 
                                            {...btnProps}
                                            sectorPosition={sectorPosition}
                                            ref={triggerRef} 
                                        />
                                    )}
                                </Tooltip>
                            </Link>
                        )
                    })}
                </TechSectorList>
            }

            <HotspotFlagElement arrowAlign={arrowAlign} onClick={onClickTooltip}>
                {labelText && <Label styleType={labelStyle}>{translations.city[labelText]}</Label>}

                <div className="inner">
                    {type !== 'secondary' && 
                        <ArrowIcon>
                            <ArrowRight /> 
                        </ArrowIcon>
                    }
                    
                    {label}
                </div>
            </HotspotFlagElement>
        </HotspotWrapper>
    )
}

export default HotspotFlag;