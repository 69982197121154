import { AppDimensions } from '../../interfaces/app-dimensions';
import Actions, { SET_APP_DIMENSIONS } from './actions';
import {StateProps} from './index'

const reducer = (state: StateProps, action: Actions): StateProps => {
    const { type, payload } = action;
    
    switch (type) {
        case SET_APP_DIMENSIONS:
            return { 
                ...state,
                appDimensions: payload as AppDimensions
            };
        default:
            return state;
    }
};

export default reducer;