import { ProfiLoungeHotspot } from "../components/profi-lounge-hotspot";
import { ArrowAlignType, LabelStyle, LabelText, TooltipType } from "../components/ui/hotspot-flag"
import { BuildingType } from "./buildings"

export type HotspotConfig = {
    coordinates: {x: number, y: number},
    building: BuildingType,
    link?: string;
    type?: TooltipType;
    comingSoon?: boolean;
    labelText?: LabelText;
    labelStyle?: LabelStyle;
    arrowAlign?: ArrowAlignType;
    subBuildings?: boolean;
    showSector?: boolean;
    component?: React.FunctionComponent<any>
}

export type HotspotList = HotspotConfig[];

export const cityHotspots: HotspotList = [
    {
        coordinates: {x: 343, y: 569},
        building: BuildingType.HOSPITAL
    },
    {
        coordinates: {x: 1520, y: 498},
        building: BuildingType.INDUSTRY,
        subBuildings: true,
    },
    /* {
        coordinates: {x: 1235, y: 395},
        building: BuildingType.SKYSCRAPER,
        type: 'inactive',
    },*/
    {
        coordinates: {x: 205, y: 900},
        building: BuildingType.HOUSE
    },
    {
        coordinates: {x: 840, y: 205},
        building: BuildingType.KSB_CENTER,
        component: ProfiLoungeHotspot
    },
    {
        coordinates: {x: 629, y: 442},
        building: BuildingType.ADMINISTRATION
    },
    {
        coordinates: {x: 709, y: 606},
        building: BuildingType.SCHOOL
    },
    {
        coordinates: {x: 1236, y: 776},
        building: BuildingType.APARTMENT
    },
    {
        coordinates: {x: 1305, y: 158},
        building: BuildingType.FACTORY,
        subBuildings: true,
    },
    {
        coordinates: {x: 670, y: 815},
        building: BuildingType.HOTEL,
    },
]

export const subBuildingRoutes: HotspotConfig[] = [
    {
        coordinates: {x: 0, y: 0},
        building: BuildingType.FACTORY_HEATING
    },
    {
        coordinates: {x: 0, y: 0},
        building: BuildingType.FACTORY_PROCESSES
    },
    {
        coordinates: {x: 0, y: 0},
        building: BuildingType.FACTORY_PRODUCTION
    },
    {
        coordinates: {x: 0, y: 0},
        building: BuildingType.FACTORY_SPRINKLER_CENTER
    },
    {
        coordinates: {x: 0, y: 0},
        building: BuildingType.INDUSTRY_LAB
    },
    {
        coordinates: {x: 0, y: 0},
        building: BuildingType.INDUSTRY_PRODUCTION
    },
    {
        coordinates: {x: 0, y: 0},
        building: BuildingType.INDUSTRY_TESTCENTER
    },
    {
        coordinates: {x: 0, y: 0},
        building: BuildingType.INDUSTRY_WATER
    },
    {
        coordinates: {x: 0, y: 0},
        building: BuildingType.INDUSTRY_SPRINKLER_CENTER
    },
]