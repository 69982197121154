import imgOutside from '../../assets/buildings/house/outside.jpg';
import { Building } from '../../interfaces/buildings';
import { TechSector } from '../tech-sector';

const skyscraperConfig: Building = {
    techSectors: [
        {
            type: TechSector.WATER,
            comingSoon: true
        },
        { 
            type: TechSector.HEATING,
            comingSoon: true
        },
        {
            type: TechSector.WASTE_WATER,
            comingSoon: true
        }
    ],
    img: imgOutside
}

export default skyscraperConfig;