import { useState } from 'react';
import { LanguageContext, LanguageContextProps } from './components/utils/language-provider';
import City from './components/city';
import DE from './i18n/de';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import theme from './config/theme';
import { BrowserRouter as Router } from "react-router-dom";
import Store from './components/store';

const GlobalStyle = createGlobalStyle`
    .page {
        &-fade-enter {
            opacity: 0;
        }

        &-fade-enter-active {
            opacity: 1;
            transition: opacity 500ms ease-in;
        }

        &-fade-exit {
            opacity: 1;
        }

        &-fade-exit-active {
            opacity: 0;
            transition: opacity 500ms ease-in;
        }

        &-zoom-enter {
            opacity: 0;
            transform: scale(3);
        }

        &-zoom-enter-active {
            opacity: 1;
            transform: scale(1);
            transition: all 500ms ease-in;
        }

        &-zoom-exit {
            opacity: 1;
            transform: scale(1);
        }

        &-zoom-exit-active {
            opacity: 0;
            transform: scale(3);
            transition: all 500ms ease-in;
        }

        &-zoom-in-enter {
            opacity: 0;
            transform: scale(3);
        }

        &-zoom-in-enter-active {
            opacity: 1;
            transform: scale(1);
            transition: all 500ms ease-in;
        }
    }
`


function App() {
    const [lang] = useState<LanguageContextProps>({
        lang: 'de',
        translations: DE
    });

    return (
        <>
            <GlobalStyle />

            <LanguageContext.Provider value={lang}>
                <Store>
                    <ThemeProvider theme={theme}>
                        <Router>
                            <City />
                        </Router>
                    </ThemeProvider>
                </Store>
            </LanguageContext.Provider>
        </>
    );
}

export default App;
