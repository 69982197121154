import styled from 'styled-components/macro';
import { LinkButton } from './button';
import useTranslations from '../utils/language-provider';
import { useResponsive } from '../utils/responsive';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';

type PageHeaderProps = {
    title: string;
    subTitle?: string;
    backBtn?: boolean;
    backBtnHref?: string;
    backBtnText?: string;
}

export const PAGE_HAEDER_HEIGHT = '75px';

const TitleWrapper = styled.span`
    text-align: center;
`

const Title = styled.span`
    text-align: center;
`
const Subtitle = styled.div`
    text-align: center;
    font-size: 12px;
    margin-top: 0.4em;
    white-space: nowrap;
`

const StyledHeader = styled.div`
    padding: 0 20px;
    display: flex;
    min-height: ${PAGE_HAEDER_HEIGHT};
    font-size: 18px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background: #fff;

    @media ${props => props.theme.screens.lg} {
        font-size: 24px;
    }

    span {
        flex: 1;
    }

    ${Title} {
        flex: 2;
    }

`

const StyledLink = styled(LinkButton)`
    margin-right: auto;

    svg {
        margin-right: 0;
        transform: rotate(180deg);
    }

`


const PageHeader = ({
    title,
    subTitle,
    backBtn = true,
    backBtnHref = '/',
    backBtnText = ''
}: PageHeaderProps) => {
    const translations = useTranslations();
    const {large} = useResponsive();

    return (
        <StyledHeader>
            <span>
                {backBtn &&
                    <StyledLink to={backBtnHref}>
                        {large ?
                            (backBtnText || translations.backToCity)
                        :
                            <ArrowRight />
                        }
                    </StyledLink>
                }
            </span>
            <TitleWrapper>
                <Title>{title}</Title>
                <Subtitle>{subTitle}</Subtitle>
            </TitleWrapper>
            <span /> {/* required for centering page title */}
        </StyledHeader>
    )
}

export default PageHeader;
