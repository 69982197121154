import imgOutside from '../../assets/buildings/hotel/outside.jpg';
import imgFireProtection from '../../assets/buildings/hotel/fire-protection.jpg';
import { Building } from '../../interfaces/buildings';
import { TechSector } from '../tech-sector';
import { ProductType } from '../products';

const hotelConfig: Building = {
    techSectors: [
        {
            type: TechSector.FIRE_PROTECTION,
            img: imgFireProtection,
            products: [
                {
                    position: {x: 776, y: 900},
                    type: ProductType.HYADUO_2_D_FL_COMPACT
                },
                {
                    position: {x: 990, y: 906},
                    type: ProductType.ETANORM_FXV
                },
            ]
        },
    ],
    img: imgOutside
}

export default hotelConfig;
