import imgOutside from '../../assets/buildings/school/outside.jpg';
import imgWasteWater from '../../assets/buildings/school/waste-water.jpg';
import imgFireProtection from '../../assets/buildings/school/fire-protection.jpg';
import imgHeating from '../../assets/buildings/school/heating.jpg';
import { Building } from '../../interfaces/buildings';
import { ProductType } from '../products';
import { TechSector } from '../tech-sector';

const schoolConfig: Building = {
    techSectors: [
        {
            type: TechSector.WASTE_WATER,
            img: imgWasteWater,
            products: [
                {
                    position: { x: 1177, y: 821 },
                    type: ProductType.AMAPORTER,
                },
                {
                    position: { x: 752, y: 893 },
                    type: ProductType.COMPACTA,
                },
                {
                    position: { x: 1601, y: 729 },
                    type: ProductType.AMA_DRAINER_BOX_UNTERFLUR,
                },
                {
                    position: { x: 1799, y: 745 },
                    type: ProductType.AMA_DRAINER_4_5,
                },
                {
                    position: { x: 1203, y: 631 },
                    type: ProductType.LEVELCONTROL,
                },
            ],
        },
        {
            type: TechSector.FIRE_PROTECTION,
            img: imgFireProtection,
            products: [
                {
                    position: { x: 952, y: 910 },
                    type: ProductType.HYADUO_2_D_FL_COMPACT,
                },
            ],
        },
        {
            type: TechSector.HEATING,
            img: imgHeating,
            products: [
                {
                    position: { x: 355, y: 852 },
                    type: ProductType.BOAX_S_SF,
                },
                {
                    position: { x: 411, y: 837 },
                    type: ProductType.SERIE_2000,
                },
                {
                    position: { x: 495, y: 845 },
                    type: ProductType.ETANORM,
                },
                {
                    position: { x: 541, y: 845 },
                    type: ProductType.BOA_CONTROL_IMS,
                },
                {
                    position: { x: 563, y: 835 },
                    type: ProductType.BOATRONIC_MS_420,
                },
                {
                    position: { x: 655, y: 780 },
                    type: ProductType.SISTO_16_RGA,
                },
                {
                    position: { x: 830, y: 790 },
                    type: ProductType.BOA_CONTROL_DPR,
                },
                {
                    position: { x: 885, y: 810 },
                    type: ProductType.CALIO_Z,
                },
                {
                    position: { x: 965, y: 760 },
                    type: ProductType.BOA_CONTROL_SBV,
                },
                {
                    position: { x: 1105, y: 775 },
                    type: ProductType.BOA_RVK,
                },
                {
                    position: { x: 1032, y: 785 },
                    type: ProductType.CALIO,
                },
                {
                    position: { x: 1180, y: 780 },
                    type: ProductType.ETALINE_Z,
                },
            ],
        },
    ],
    img: imgOutside,
};

export default schoolConfig;