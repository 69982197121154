import { forwardRef } from 'react';
import styled from 'styled-components/macro';
import BuildingWrapper from './building-wrapper';
import PageHeader from './ui/page-header';
import bgImg from '../assets/buildings/ksb-center/bg.jpg';
import useTranslations from './utils/language-provider';
import { BuildingType } from '../config/buildings';
import { Anchor } from './ui/button';
import { ReactComponent as ArrowRight } from '../assets/arrow-right.svg';
import TeaserEtalinePro from "./ksb-center-teaser/teaser-etalinepro";

type KSBCenterProps = {}

const Background = styled.div`
    height: calc(100% - 75px);
    background: url(${bgImg});
    background-size: contain;
    background-repeat: no-repeat;

    @media ${props => props.theme.screens.lg} {
        padding: 130px 100px 0;
    }
`

const Headline = styled.h2`
    display: none;
    font-size: 28px;
    margin: 0 0 20px;
    font-weight: bold;

    @media ${props => props.theme.screens.lg} {
        display: block;
    }
`

const Row = styled.div`
    display: flex;
`;

const Col = styled.div`
    flex: 1;
`
const LinkCol = styled(Col)`
    display: none;
    flex-direction: column;
    align-items: flex-end;

    @media ${props => props.theme.screens.lg} {
        display: flex;
    }
`

const LinkList = styled.div`
    max-width: 250px;

    a {
        margin: 0 0 10px;
    }
`

const NewsletterHeadline = styled.h3`
    margin: 30px 0 10px;
    max-width: 280px;
    font-size: 20px;
`

const KSBCenter = forwardRef<HTMLDivElement, KSBCenterProps>((props, ref) => {
    const translations = useTranslations();

    return (
        <BuildingWrapper ref={ref}>
            <PageHeader title={translations.buildings[BuildingType.KSB_CENTER].name} />

            <Background>
                <Row>
                    <Col style={{flex: 2}}>
                        <Headline>
                            {translations.buildings[BuildingType.KSB_CENTER].headline}
                        </Headline>

                        <TeaserEtalinePro />
                    </Col>

                    <LinkCol>
                        <LinkList>
                            {translations.buildings[BuildingType.KSB_CENTER]?.linkList.map(link => (
                                <Anchor key={link.text} href={link.href} align="left">
                                    <ArrowRight /> {link.text}
                                </Anchor>
                            ))}
                        </LinkList>

                        <NewsletterHeadline>{translations.newsletter.title}</NewsletterHeadline>

                        <Anchor href={translations.newsletter.link.href} secondary align="left">
                            <ArrowRight /> {translations.newsletter.link.text}
                        </Anchor>
                    </LinkCol>

                </Row>
            </Background>
        </BuildingWrapper>
    )
})

export default KSBCenter;
