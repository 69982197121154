import imgOutside from '../../assets/buildings/apartment/outside.jpg';
import imgWater from "../../assets/buildings/apartment/water.jpg";
import imgWasteWater from '../../assets/buildings/apartment/waste-water.jpg';
import imgHeating from '../../assets/buildings/apartment/heating.jpg';
import imgFireProtection from '../../assets/buildings/apartment/fire-protection.jpg';
import { Building } from '../../interfaces/buildings';
import { ProductType } from '../products';
import { TechSector } from '../tech-sector';

const apartmentConfig: Building = {
    techSectors: [
        {
            type: TechSector.HEATING,
            img: imgHeating,
            products: [
                {
                    position: {x: 275, y: 880},
                    type: ProductType.BOAX_S_SF
                },
                {
                    position: {x: 420, y: 910},
                    type: ProductType.ETANORM
                },
                {
                    position: {x: 355, y: 860},
                    type: ProductType.SERIE_2000
                },
                {
                    position: {x: 550, y: 855},
                    type: ProductType.BOA_CONTROL_IMS
                },
                {
                    position: {x: 585, y: 850},
                    type: ProductType.BOATRONIC_MS_420
                },
                {
                    position: {x: 970, y: 800},
                    type: ProductType.CALIO_Z
                },
                {
                    position: {x: 1112, y: 780},
                    type: ProductType.CALIO
                },
                {
                    position: {x: 1180, y: 740},
                    type: ProductType.BOA_CONTROL_DPR
                },
                {
                    position: {x: 490, y: 300},
                    type: ProductType.BOA_CONTROL_PIC
                },
                {
                    position: {x: 1235, y: 715},
                    type: ProductType.BOA_CONTROL_SBV
                },
                {
                    position: {x: 1333, y: 745},
                    type: ProductType.BOA_RVK
                },
                {
                    position: {x: 1430, y: 760},
                    type: ProductType.ETALINE_Z
                },
            ]
        },
        {
            type: TechSector.WATER,
            img: imgWater,
            products: [
                {
                    position: {x: 90, y: 565},
                    type: ProductType.MULTI_ECO
                },
                {
                    position: {x: 425, y: 730},
                    type: ProductType.HYA_RAIN_N
                },
                {
                    position: {x: 637, y: 790},
                    type: ProductType.DELTA_BASIC_COMPACT_MVP
                },
                {
                    position: {x: 1018, y: 752},
                    type: ProductType.SISTO_16_RGA
                },
            ]
        },
        {
            type: TechSector.WASTE_WATER,
            img: imgWasteWater,
            products: [
                {
                    position: {x: 1302, y: 749},
                    type: ProductType.MINI_COMPACTA
                },
                {
                    position: {x: 1517, y: 721},
                    type: ProductType.COMPACTA
                },
                {
                    position: {x: 1854, y: 667},
                    type: ProductType.AMA_DRAINER_BOX_UNTERFLUR
                },
                {
                    position: {x: 353, y: 820},
                    type: ProductType.AMA_DRAINER_4_5
                },
                {
                    position: {x: 777, y: 612},
                    type: ProductType.LEVELCONTROL
                }
            ]
        },
        {
            type: TechSector.FIRE_PROTECTION,
            img: imgFireProtection,
            products: [
                {
                    position: {x: 965, y: 833},
                    type: ProductType.HYADUO_2_D_FL_COMPACT
                },
            ]
        }
    ],
    img: imgOutside
}

export default apartmentConfig;
