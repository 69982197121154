import { useState } from 'react';
import styled from 'styled-components/macro';
import classNames from 'classnames';
import { ReactComponent as Pin } from '../../assets/pin.svg';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import { ProductType } from '../../config/products';
import useTranslations from '../utils/language-provider';
import { usePopperTooltip } from 'react-popper-tooltip';

type ProductPinProps = {
    productType: ProductType;
    showNewFlag?: boolean;
    style?: React.CSSProperties;
    className?: string;
    popoverOpenDefault?: boolean;
}

const PinInner = styled.div`
    position: relative;
    z-index: 2;
`

const PinWrapper = styled.div`
    position: relative;
    z-index: 2;
    color: #fff;
    margin-top: -53px;
    margin-left: -17px;
    
    @media ${props => props.theme.screens.lg} {
        margin-top: -109px;
        margin-left: -39px;
    }

    &.front {
        z-index: 3000;
    }

    ${PinInner}:hover {
        cursor: pointer;
    
        .pin {
            color: ${props => props.theme.colors.primaryLight};
        }

        .arrow {
            top: 12px;
        }
    }

    .pin {
        width: 35px;
        color: ${props => props.theme.colors.primary};
        transition: all 100ms ease-in;
        
        @media ${props => props.theme.screens.lg} {
            width: 75px;
        }

        &:hover {
            cursor: pointer;
            color: ${props => props.theme.colors.primaryLight};

            + .arrow {
                top: 12px;
            }
        }
    }

    .arrow {
        position: absolute;
        top: 10px;
        left: 50%;
        height: 15px;
        transform: rotate(-90deg);
        margin-left: -4px;
        transition: all 100ms ease-in;
        
        @media ${props => props.theme.screens.lg} {
            top: 15px;
            height: 40px;
            margin-left: -10px;
        }
    }
`

const Arrow = styled.div`
    width: 33px;
    height: 20px;

    @media ${props => props.theme.screens.lg} {
        width: 56px;
        height: 40px;
    }
`

const ArrowInner = styled.div`
    width: 100%;
    height: 100%;
    background: #fff;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: transform 300ms;
`

const MenuItem = styled.li`
    white-space: nowrap;
    box-shadow: 0 5px 10px rgba(0,0,0,.6);
`

const MenuItemHead = styled(MenuItem)`
    padding: 4px 12px 3px;
    background: ${props => props.theme.colors.primary};
    font-weight: bold;
    color: #fff;
    font-size: 18px;
    
    @media ${props => props.theme.screens.lg} {
        padding: 9px 23px 7px;
        font-size: 26px;
    }
`

const MenuLink = styled.a`
    display: block;
    padding: 4px 5px 3px;
    background: #fff;
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    font-size: 12px;
    transition: background 100ms ease-in;
    cursor: pointer;
    color: #000;
    text-decoration: none;

    @media ${props => props.theme.screens.lg} {
        padding: 8px 10px 7px;
        font-size: 14px;
    }

    &:hover {
        color: #fff;
        background-color: ${props => props.theme.colors.primary};
    }
`

const Menu = styled.ul`
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;
`

const Popper = styled.div`
    ${MenuItem},
    ${MenuItemHead} {
        position: relative;
        top: 20px;
        opacity: 0;
        transition: top 200ms, opacity 200ms;
    }

    &.open {
        ${MenuItem},
        ${MenuItemHead} {
            opacity: 1;
            top: 0;
        }

        ${ArrowInner} {
            transform: scaleY(1);
        }
    }
`

const NewFlag = styled.div`
    position: relative;
    display: inline-block;
    top: -73px;
    left: 50%;
    transform: translateX(-50%);
    background: ${props => props.theme.colors.secondary};
    text-transform: uppercase;
    padding: 4px 3px 10px;
    font-size: 12px;
    
    @media ${props => props.theme.screens.lg} {
        top: -145px;
        padding: 8px 5px 20px;
        font-size: 18px;
    }
`

const ProductPin = ({
    style = {},
    className = '',
    showNewFlag = false,
    popoverOpenDefault = false,
    productType,
}: ProductPinProps) => {
    const [popoverOpen, setPopoverOpen] = useState(popoverOpenDefault);
    const [menuOpen, setMenuOpen] = useState(popoverOpenDefault);
    const translations = useTranslations();
    const productLinks = translations.products[productType]?.links || [];

    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip({
        placement: 'top',
        trigger: 'click',
        visible: popoverOpen,
        onVisibleChange(state) {
            if (state === false) {
                handleClose();
            }
        }
    });

    const handleClose = () => {
        setPopoverOpen(false);
        setMenuOpen(false);
    }

    const handleOpen = () => {
        setPopoverOpen(true);

        setTimeout(() => {
            setMenuOpen(true);
        }, 100);
    }
    

    return (
        <>
            <PinWrapper style={style} className={classNames(className, {front: menuOpen})}>
                <PinInner ref={setTriggerRef} onClick={() => handleOpen()}>
                    <Pin className="pin" />
                    <ArrowRight className="arrow" />
                </PinInner>

                {showNewFlag &&
                    <NewFlag>
                        {translations.buildingDetails.new}
                    </NewFlag>
                }

                {visible &&
                    <Popper ref={setTooltipRef} {...getTooltipProps()} className={classNames({open: menuOpen})}>
                        <Menu>
                            <MenuItemHead style={{transitionDelay: `${productLinks.length * 100 + 100}ms`}}>
                                {translations.products[productType].name}
                            </MenuItemHead>

                            {productLinks.map((link, index) => (
                                <MenuItem key={link.text + index} style={{transitionDelay: `${(productLinks.length - index) * 100 }ms`}}>
                                    <MenuLink href={link.href}>
                                        {link.text}
                                    </MenuLink>
                                </MenuItem>
                            ))}
                        </Menu>
                        <Arrow {...getArrowProps()}>
                            <ArrowInner />
                        </Arrow>
                    </Popper>
                }
            </PinWrapper>
        </>
    )
}

export default ProductPin;