import classNames from 'classnames';
import { HotspotFlagElement, HotspotWrapper } from './ui/hotspot-flag';
import teamImg from "../assets/team.png";
import globeImg from "../assets/globe.png";
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { SpecialRoutes } from '../config/app';

type HotspotProps = {
    style?: React.CSSProperties;
    className?: string;
}

const Sections = styled.div`
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    padding: 0 15px 5px;

    @media ${props => props.theme.screens.md} {
        padding: 0;
    }
    
    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        transform: translateY(100%);
        transition: all 500ms;
    }

    img {
        display: none;

        @media ${props => props.theme.screens.md} {
            display: block;
            height: 40px;
        }

        @media ${props => props.theme.screens.lg} {
            height: 50px;

            &.globe {
                margin-top: -12px;
                height: 65px;
            }
        }
    }
`

const SectionLabel = styled.span`
    background: rgba(255,255,255,.66);
    color: #a8a8a8;
    font-size: 12px;
    border-radius: 5px;
    padding: 0 5px;

    @media ${props => props.theme.screens.lg} {
        font-size: 18px;
        padding: 3px 15px 2px;
    }
`

const Wrapper = styled(HotspotWrapper)`
    padding: 17px 0 0 6px;

    @media ${props => props.theme.screens.md} {
        padding: 0;
    }


    .inner {
        justify-content: center;

        @media ${props => props.theme.screens.lg} {
            height: 40px;
        }
    }

    &:hover {
        a {
            transform: translateY(0);
        }
    }
`

export const ProfiLoungeHotspot = ({
    style,
    className
}: HotspotProps) => {

    return (
        <Wrapper
            style={style}
            className={classNames(className, 'secondary')}
        >   
            <Sections>
                <Link to={SpecialRoutes.KSB_TEAM}>
                    <SectionLabel>
                        Team
                    </SectionLabel>
                    <img src={teamImg} alt="" />
                </Link>
                <Link to={SpecialRoutes.KSB_CENTER} style={{transitionDelay: '100ms'}}>
                    <SectionLabel>
                        News
                    </SectionLabel>
                    <img src={globeImg} className="globe" alt="" />
                </Link>
            </Sections>
            <HotspotFlagElement arrowAlign="center">
                <div className="inner">
                    KSB Profi Lounge
                </div>
            </HotspotFlagElement>
        </Wrapper>
    )
}