import { createContext, Dispatch, useContext, useReducer } from 'react';
import { AppDimensions } from '../../interfaces/app-dimensions';
import Actions from './actions';
import reducer from './reducer';

export type StateProps = {
    appDimensions: AppDimensions
}

const initialState: StateProps = {
    appDimensions: {
        width: 0,
        height: 0
    }
};

type ContextProps = {
    state: StateProps;
    dispatch: Dispatch<Actions>;
}

const StoreContext = createContext<ContextProps>({
    dispatch: () => {},
    state: initialState
});

const Store: React.FC = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <StoreContext.Provider value={{ state, dispatch }}>
            {children}
        </StoreContext.Provider>
    );
};

export const useStore = () => {
    const store = useContext(StoreContext);
    return store;
}

export default Store;