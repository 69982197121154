import styled from 'styled-components/macro';
import classNames from 'classnames';
import BuildingNavigation from './ui/building-navigation';
import { createRef, forwardRef, useEffect, useState } from 'react';
import { useStore } from './store';
import { buildings } from '../config/building-config';
import { BuildingType } from '../config/buildings';
import useTranslations from './utils/language-provider';
import { useParams } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import ProductPin from './ui/product-pin';
import { getOffsetPosition } from './utils/position';
import { products } from '../config/products';
import { PinchPanImg, PinchPan } from "./utils/pinch-pan-helpers";
import { PropsList } from 'react-zoom-pan-pinch/dist/store/interfaces/propsInterface';
import PageHeader from './ui/page-header';
import BuildingWrapper from './building-wrapper';
import { RouteParams } from '../interfaces/router';

type BuildingDetailsProps = {
    type: BuildingType;
}

const StyledNavigation = styled(BuildingNavigation)`
    position: absolute;
    z-index: 300;
    top: 20px;
    left: 20px;
`

const StyledProductPin = styled(ProductPin)`
    position: absolute;
    --animate-duration: 700ms;
    opacity: 0;

    &.active {
        opacity: 1;
    }
`

const BuildingInner = styled.div`
    position: absolute;
    top: 0;
`

const BuildingImage = styled.div`
    position: relative;
`

const BuildingDetails = forwardRef<HTMLDivElement, BuildingDetailsProps>(({
    type
}, ref) => {
    const {state} = useStore();
    const translations = useTranslations();
    const config = buildings[type] ;
    const sectors = config?.techSectors;
    const {techSector: techSectorParam = '', product: productParam} = useParams<RouteParams>();
    const [pinsActive, setPinsActive] = useState(false);
    const buildingInnerRef = createRef<HTMLDivElement>();

    const techSectorConfig = techSectorParam ? config.techSectors?.find(sector => sector.type === techSectorParam) : null;

    const imgPath = techSectorConfig?.img ? techSectorConfig.img : config.img || '';
    const productsConfig = techSectorConfig?.products || config.products;

    useEffect(() => {
        setTimeout(() => {
            setPinsActive(true);
        }, 1000)

    }, [type, translations.buildings])

    return (
        <BuildingWrapper ref={ref}>
            {config?.parentBuilding
            ?
                <PageHeader title={translations.buildings[type].name} backBtnText={translations.back} backBtnHref={`/${config.parentBuilding}/${techSectorParam}`} />
            :
                <PageHeader title={translations.buildings[type].name} />
            }

            <BuildingImage>
                {!!sectors?.length &&
                    <StyledNavigation buildingType={type} />
                }

                <SwitchTransition mode="in-out">
                    <CSSTransition
                        key={techSectorParam}
                        timeout={500}
                        classNames="page-fade"
                        nodeRef={buildingInnerRef}
                    >
                        <BuildingInner ref={buildingInnerRef}>
                            <PinchPan>
                                {({ positionX: imgOffsetX, positionY: imgOffsetY, scale: imgScale }: PropsList) => {
                                    return (
                                        <>
                                            <PinchPanImg src={imgPath} />

                                            {productsConfig &&
                                                productsConfig.map((product, index) => (
                                                    <StyledProductPin
                                                        popoverOpenDefault={productParam === product.type}
                                                        key={`${product.type}${index}`}
                                                        productType={product.type}
                                                        showNewFlag={products[product.type].new}
                                                        className={classNames('animated', {
                                                            bounceInDown: pinsActive,
                                                            active: pinsActive,
                                                        })}
                                                        style={{
                                                            ...getOffsetPosition({
                                                                x: product.position.x,
                                                                y: product.position.y,
                                                                imgWidth: state.appDimensions.width,
                                                                imgHeight: state.appDimensions.height,
                                                                imgOffsetX, imgOffsetY, imgScale
                                                            }),
                                                            animationDelay: `${index * 100}ms`,
                                                        }}
                                                    />
                                                ))
                                            }
                                        </>
                                    )
                                }}
                            </PinchPan>
                        </BuildingInner>
                    </CSSTransition>
                </SwitchTransition>
            </BuildingImage>
        </BuildingWrapper>
    )
})

export default BuildingDetails;
