import styled from 'styled-components/macro';
import useTranslations from '../utils/language-provider';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';

const circleSizeDefault = 60;
const circleSizeLarge = 120;

const StyledArrow = styled(ArrowRight)`
    position: relative;
    top: 0;
    transform: rotate(-90deg);
    height: 20px;
    transition: all 100ms ease-in;

    @media ${props => props.theme.screens.lg} {
        margin: 0 0 3px;
    }
`

const StyledLink = styled.a`
    position: absolute;
    z-index: 9999;
    width: ${circleSizeDefault}px;
    height: ${circleSizeDefault}px;
    bottom: -${circleSizeDefault / 2}px;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transform: translateX(-50%);
    text-decoration: none;
    background: ${props => props.theme.colors.secondary};
    color: #fff;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    transition: all 100ms ease-in;
    font-size: 10px;
    line-height: 6px;

    @media ${props => props.theme.screens.lg} {
        padding: 8px 0 0;
        width: ${circleSizeLarge}px;
        height: ${circleSizeLarge}px;
        bottom: -${circleSizeLarge / 2}px;
        font-size: 16px;
        line-height: initial;
    }

    &:hover {
        background: ${props => props.theme.colors.secondaryLight};

        ${StyledArrow} {
            top: -3px;
        }
    }
`

const ContactFlag = () => {
    const translations = useTranslations();

    return (
        <StyledLink href={translations.contact.href}>
            <StyledArrow />
            {translations.contact.text}
        </StyledLink>
    )
}

export default ContactFlag;