import styled from "styled-components/macro";
import { usePopperTooltip } from 'react-popper-tooltip';

type ChildrenCallbackProps = {
    triggerRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

type TooltipProps = {
    children({triggerRef}: ChildrenCallbackProps): React.ReactNode;   
    content: React.ReactNode ;  
}

const StyledTooltip = styled.div`
    padding: 6px 10px 5px;
    background: rgba(255, 255, 255, .9);
    color: ${props => props.theme.colors.gray2};
    font-weight: 700;
    font-size: 14px;
    border-radius: 3px;
    z-index: 9000;
`

const Tooltip = ({
    children,
    content
}: TooltipProps) => {
    const {
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip({
        placement: 'top',
    });

    const ref = children({triggerRef: setTriggerRef});

    return (
        <>
            {ref}
            {visible &&
                <StyledTooltip ref={setTooltipRef} {...getTooltipProps()}>{content}</StyledTooltip>
            }
        </>

    )
}

export default Tooltip;