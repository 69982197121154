import { DefaultTheme } from "styled-components";
import { TechSector } from "../../config/tech-sector";

export const getTechSectorBackground = (theme: DefaultTheme, sectorType: TechSector | undefined, inactive = false) => {
    if (inactive || !sectorType) return theme.colors.gray1;

    const colors = theme.colors.sectors[sectorType];

    if (Array.isArray(colors)) {
        return `linear-gradient(to right, ${colors[0]} 50%,${colors[0]} 50%,${colors[1]} 50%)`
    }

    return colors;
}