import { forwardRef } from 'react';
import styled from 'styled-components/macro';
import { toPx } from './utils/position';
import { useStore } from './store';

const Inner = styled.div`
    margin: 0 auto;
    overflow: hidden;
    height: 100%;
`

const Outer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
`

const BuildingWrapper = forwardRef<HTMLDivElement, {children : React.ReactNode}>(({children}, ref) => {
    const {state} = useStore();

    return (
        <Outer ref={ref}>
            <Inner style={{width: toPx(state.appDimensions.width)}}>
                {children}
            </Inner>
        </Outer>
    )

})

export default BuildingWrapper;