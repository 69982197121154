import { BuildingType } from '../buildings';
import imgOutside from '../../assets/buildings/factory/outside.jpg';
import imgHeating from '../../assets/buildings/factory/heating.jpg';
import imgProduction from '../../assets/buildings/factory/production.jpg';
import imgProcesses from '../../assets/buildings/factory/processes.jpg';
import imgFireProtection from '../../assets/buildings/factory/fire-protection.jpg';
import imgSprinklerCenter from '../../assets/buildings/factory/sprinkler-center.jpg';
import { BuildingArea, SubBuilding } from '../../interfaces/buildings';
import { ProductType } from '../products';
import { TechSector } from '../tech-sector';

const factoryConfig: BuildingArea = {
    sectorPosition: 'bottom',
    techSectors: [
        {
            type: TechSector.HEATING,
            img: imgOutside,
            buildings: [
                {
                    coordinates: {x: 495, y: 308},
                    building: BuildingType.FACTORY_PRODUCTION,
                    link: `${BuildingType.FACTORY_PRODUCTION}/${TechSector.HEATING}`,
                    showSector: false
                },
                {
                    coordinates: {x: 1181, y: 199},
                    building: BuildingType.FACTORY_PROCESSES,
                    link: `${BuildingType.FACTORY_PROCESSES}/${TechSector.HEATING}`,
                    showSector: false
                },
                {
                    coordinates: {x: 1302, y: 620},
                    building: BuildingType.FACTORY_HEATING,
                    link: `${BuildingType.FACTORY_HEATING}/${TechSector.HEATING}`,
                    showSector: false
                }
            ]
        },
        {
            type: TechSector.FIRE_PROTECTION,
            img: imgFireProtection,
            buildings: [
                {
                    coordinates: {x: 440, y: 680},
                    building: BuildingType.FACTORY_SPRINKLER_CENTER,
                    link: `${BuildingType.FACTORY_SPRINKLER_CENTER}/${TechSector.FIRE_PROTECTION}`,
                    showSector: false
                },
            ]
        }
    ],
    img: imgOutside
}

export const factoryHeatingConfig: SubBuilding = {
    parentBuilding: BuildingType.FACTORY,
    img: imgHeating,
    techSectors: [
        {
            type: TechSector.HEATING,
        }
    ],
    products: [
        {
            position: {x: 1555, y: 779},
            type: ProductType.ETANORM
        },
        {
            position: {x: 400, y: 602},
            type: ProductType.ETALINE
        },
        {
            position: {x: 818, y: 591},
            type: ProductType.BOA_CVE
        },
        {
            position: {x: 887, y: 700},
            type: ProductType.SISTO_RSK
        },
        {
            position: {x: 874, y: 475},
            type: ProductType.BOAX_MAT
        },
        {
            position: {x: 1127, y: 761},
            type: ProductType.BOA_CONTROL_IMS
        },
        {
            position: {x: 1403, y: 684},
            type: ProductType.BOAX_S_SF
        },
        {
            position: {x: 1621, y: 644},
            type: ProductType.SERIE_2000
        },
        {
            position: {x: 1658, y: 854},
            type: ProductType.HPK
        }
    ]
}

export const factoryProcessesConfig: SubBuilding = {
    parentBuilding: BuildingType.FACTORY,
    img: imgProcesses,
    techSectors: [
        {
            type: TechSector.HEATING,
        }
    ],
    products: [
        {
            position: {x: 1061, y: 593},
            type: ProductType.MEGA_CPK
        },
        {
            position: {x: 1156, y: 365},
            type: ProductType.ECOLINE_GT_40
        },
        {
            position: {x: 917, y: 413},
            type: ProductType.SISTO_RSK
        },
    ]
}

export const factoryProductionConfig: SubBuilding = {
    parentBuilding: BuildingType.FACTORY,
    img: imgProduction,
    techSectors: [
        {
            type: TechSector.HEATING,
        }
    ],
    products: [
        {
            position: {x: 212, y: 469},
            type: ProductType.MOVITEC_VCI
        },
        {
            position: {x: 1000, y: 525},
            type: ProductType.MOVITEC
        }
    ]
}

export const factorySprinklerCenterConfig: SubBuilding = {
    parentBuilding: BuildingType.FACTORY,
    img: imgSprinklerCenter,
    techSectors: [
        {
            type: TechSector.FIRE_PROTECTION,
        }
    ],
    products: [
        {
            position: {x: 500, y: 690},
            type: ProductType.UPA_VDS
        },
        {
            position: {x: 1330, y: 561},
            type: ProductType.HYADUO_2_D_FL_COMPACT
        },
        {
            position: {x: 1189, y: 508},
            type: ProductType.MOVITEC
        },
    ]
}

export default factoryConfig;