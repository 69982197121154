import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { TechSector } from '../../config/tech-sector';
import useTranslations from '../utils/language-provider';
import TechSectorIcon from './tech-sector-icon';
import { BuildingType } from '../../config/buildings';
import { Link, useParams } from 'react-router-dom';
import { formatBuildingRoute } from '../utils/router';
import { getTechSectorBackground } from '../utils/colors';
import { RouteParams } from '../../interfaces/router';
import { buildings } from '../../config/building-config';

type BuildingNavigationProps = {
    buildingType: BuildingType;
}

type TechSectorLabelProps = {
    sectorType?: TechSector;
}

const NavigationWrapper = styled.div`
    position: absolute;
    z-index: 300;
    top: 20px;
    left: 20px;
    font-size: 14px;
    
    @media ${props => props.theme.screens.lg} {
        width: 280px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, .6);
    }
`

const Inner = styled.div`
    @media ${props => props.theme.screens.lg} {
        padding: 15px 10px;
        background: #fff;
    }
`

const Title = styled.h3`
    margin: 0 0 10px;
    text-align: center;
    font-size: 14px;
    display: none;

    @media ${props => props.theme.screens.lg} {
        display: block;
    }
`

const StyledTechSectorButton = styled(TechSectorIcon)`
    top: -20px;
    width: 35px;
    height: 35px;
    margin: 0 0 15px;
    opacity: 0;
    
    @media ${props => props.theme.screens.lg} {
        width: 50px;
        height: 50px;
        margin: 0;
        box-shadow: none;
    }
`;

const TechSectorList = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media ${props => props.theme.screens.lg} {
        flex-direction: row;
    }

    &.active {
        ${StyledTechSectorButton} {
            opacity: 1;
            top: 0;
        }
    }
`

const Label = styled.div`
    padding: 6px 0;
    min-height: 29px;
    transition-delay: 300ms;
    font-weight: bold;
    text-align: center;
    background: #fff;
`

const TechSectorLabel = styled.div<TechSectorLabelProps>`
    overflow: hidden;
    max-height: 0;
    transition-delay: 100ms;
    display: none;

    @media ${props => props.theme.screens.lg} {
        display: block;
    }

    &::before {
        display: block;
        content: "";
        height: 3px;
        background: ${({theme, sectorType}) => getTechSectorBackground(theme, sectorType)};
    }

    &.active {
        max-height: 100px;
    }

    &.slide-enter {
        max-height: 0;
    }

    &.slide-enter-active {
        max-height: 100px;
        transition: all 300ms;
    }

    &.slide-exit {
        max-height: 0;
    }

    &.slide-exit-active {
        max-height: 0;
        transition: all 300ms;
    }
`

const Description = styled.div`
    font-size: 12px;
    padding: 5px 10px;
    border-top: 3px solid ${props => props.theme.colors.gray1};
    background: #fff;
`

const BuildingNavigation = ({
    buildingType,
    ...props
}: BuildingNavigationProps) => {
    const translations = useTranslations();
    const [sectorButtonsVisible, setSectorButtonsVisible] = useState(false);
    const {techSector: routeTechSector} = useParams<RouteParams>();
    const [selectedTechSector, setSelectedTechSector] = useState<TechSector|undefined>(routeTechSector);
    const sectors = buildings[buildingType].techSectors;
    
    useEffect(() => {
        setTimeout(() => {
            setSectorButtonsVisible(true);
        }, 1000);
    }, [sectorButtonsVisible])

    useEffect(() => {
        setSelectedTechSector(routeTechSector);
    }, [routeTechSector])

    const handleTechSectorMouseOver = (sectorType: TechSector) => {
        if (sectorType !== selectedTechSector) {
            setSelectedTechSector(sectorType);
        }
    }

    const handleTechSectorMouseOut = () => {
        setSelectedTechSector(routeTechSector);
    }

    return (
        <NavigationWrapper {...props}>
            <Inner>
                <Title>{translations.buildingDetails.navigationTitle}</Title>

                <TechSectorList className={classNames({active: sectorButtonsVisible})}>
                    {sectors?.map((sector, index) => {
                        const btnProps = {
                            sectorType: sector.type,
                            style: {
                                transitionDelay: `${100 * index}ms`
                            }
                        } 

                        if (sector.comingSoon) {
                            return <StyledTechSectorButton key={sector.type} {...btnProps} inactive />
                        }

                        return (
                            <Link key={sector.type} to={formatBuildingRoute({building: buildingType, techSector: sector.type})}>
                                <StyledTechSectorButton
                                    {...btnProps}
                                    className={classNames({active: routeTechSector === sector.type})}
                                    onHover={[
                                        () => handleTechSectorMouseOver(sector.type), 
                                        handleTechSectorMouseOut
                                    ]}
                                />
                            </Link>
                        )
                    })}
                </TechSectorList>
            </Inner>

            <CSSTransition in={!!selectedTechSector} timeout={300} classNames="slide" className={classNames({active: !!selectedTechSector})}>
                <TechSectorLabel sectorType={selectedTechSector}>
                    <Label>
                        {selectedTechSector && translations.sectors[selectedTechSector].name}
                    </Label>

                    {routeTechSector &&
                        <Description>
                            {translations.buildingDetails.navigationDescription}
                        </Description>
                    }
                </TechSectorLabel>
            </CSSTransition>

        </NavigationWrapper>
    )
}

export default BuildingNavigation;