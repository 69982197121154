import { Product } from "../interfaces/products";

export enum ProductType {
    AMA_DRAINER_4_5 = 'ama-drainer-4-5',
    AMA_DRAINER_BOX_UNTERFLUR = 'ama-drainer-box-unterflur',
    AMA_DRAINER_BOX_UEBERFLUR = 'ama-drainer-box-ueberflur',
    AMA_DRAINER_3 = 'ama-drainer-3',
    AMAPORTER = 'amaporter',
    AMAREX = 'amarex',
    BOA_COMPACT = 'boa-compact',
    BOA_COMPACT_EKB = 'boa-compact-ekb',
    BOA_CVE = 'boa-cve',
    BOA_CVE_IMS = 'boa-cve-ims',
    BOA_RVK = 'boa-rvk',
    BOA_CONTROL_IMS = 'boa-control-ims',
    BOA_CONTROL_PIC = 'boa-control-pic',
    BOA_CONTROL_DPR = 'boa-control-dpr',
    BOA_CONTROL_SBV = 'boa-control-sbv',
    BOATRONIC_MS_420 = 'boatronic-ms-420',
    BOAX_MAT = 'boax-mat',
    BOAX_S_SF = 'boax-s-sf',
    CALIO = 'calio',
    CALIO_Z = 'calio-z',
    CALIO_THERM_S = 'calio-therm-s',
    CALIO_S = 'calio-s',
    CK_800 = 'ck-800',
    COMPACTA = 'compacta',
    DELTA_BASIC_COMPACT_MVP = 'delta-compact',
    ECOLINE_GT_40 = 'ecoline-gt-40',
    ETALINE = 'etaline',
    ETALINE_Z = 'etaline-z',
    ETANORM = 'etanorm',
    ETANORM_FXV = 'etanorm-fxv',
    FILTRA = 'filtra',
    HPK = 'hpk',
    HYA_RAIN_N = 'hya-rain',
    HYADUO_2_D_FL_COMPACT = 'hyaduo-2-d-fl-compact',
    HYA_DUO_2_D_FL = 'hya-duo-2-d-fl',
    HYASOLO_2_D_FL_COMPACT = 'hyasolo-2-d-fl-compact',
    IXO_PRO = 'ixo-pro',
    KSB_DELTA_PRIMO_SVP = 'ksb-delta-primo-svp',
    KSB_SAFETY_BOOST = 'ksb-safety-boost',
    LEVELCONTROL = 'levelcontrol',
    MEGA_CPK = 'mega-cpk',
    MINI_COMPACTA = 'mini-compacta',
    MOVITEC = 'movitec',
    MOVITEC_VCI = 'movitec-vci',
    MULTI_ECO = 'multi-eco',
    SERIE_2000 = 'serie-2000',
    SISTO_16_RGA = 'sisto-16rga',
    SISTO_16_TWA = 'sisto-16twa',
    SISTO_16 = 'sisto-16',
    SISTO_RSK = 'sisto-rsk',
    UPA = 'upa',
    UPA_VDS = 'upda-vds',
}

type ProductsProps = {
    [key in ProductType]: Product
}

export const products: ProductsProps = {
    [ProductType.AMA_DRAINER_3]: {
        new: true,
    },
    [ProductType.MINI_COMPACTA]: {},
    [ProductType.AMA_DRAINER_BOX_UNTERFLUR]: {},
    [ProductType.CK_800]: {},
    [ProductType.AMAREX]: {},
    [ProductType.COMPACTA]: {},
    [ProductType.AMA_DRAINER_4_5]: {},
    [ProductType.AMA_DRAINER_BOX_UEBERFLUR]: {},
    [ProductType.AMAPORTER]: {},
    [ProductType.LEVELCONTROL]: {},
    [ProductType.BOAX_S_SF]: {},
    [ProductType.ETANORM]: {},
    [ProductType.ETANORM_FXV]: {},
    [ProductType.CALIO]: {},
    [ProductType.CALIO_Z]: {},
    [ProductType.BOA_RVK]: {},
    [ProductType.BOA_COMPACT]: {},
    [ProductType.BOA_CONTROL_PIC]: {},
    [ProductType.BOATRONIC_MS_420]: {},
    [ProductType.BOA_CONTROL_DPR]: {},
    [ProductType.BOA_CONTROL_SBV]: {},
    [ProductType.BOA_CVE_IMS]: {},
    [ProductType.ETALINE_Z]: {},
    [ProductType.SISTO_16]: {},
    [ProductType.CALIO_THERM_S]: {},
    [ProductType.CALIO_S]: {},
    [ProductType.MULTI_ECO]: {},
    [ProductType.FILTRA]: {},
    [ProductType.HYA_RAIN_N]: {},
    [ProductType.HYADUO_2_D_FL_COMPACT]: {},
    [ProductType.HYA_DUO_2_D_FL]: {},
    [ProductType.HYASOLO_2_D_FL_COMPACT]: {},
    [ProductType.DELTA_BASIC_COMPACT_MVP]: {},
    [ProductType.IXO_PRO]: {},
    [ProductType.BOA_COMPACT_EKB]: {},
    [ProductType.SISTO_16_TWA]: {},
    [ProductType.SISTO_16_RGA]: {},
    [ProductType.MOVITEC]: {},
    [ProductType.KSB_DELTA_PRIMO_SVP]: {},
    [ProductType.KSB_SAFETY_BOOST]: {},
    [ProductType.UPA]: {},
    [ProductType.MOVITEC_VCI]: {},
    [ProductType.MEGA_CPK]: {},
    [ProductType.ECOLINE_GT_40]: {},
    [ProductType.SISTO_RSK]: {},
    [ProductType.ETALINE]: {},
    [ProductType.BOA_CVE]: {},
    [ProductType.BOAX_MAT]: {},
    [ProductType.BOA_CONTROL_IMS]: {},
    [ProductType.SERIE_2000]: {},
    [ProductType.HPK]: {},
    [ProductType.UPA_VDS]: {},
}
