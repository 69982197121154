import { BuildingType } from "../../config/buildings"
import { RouteParams } from "../../interfaces/router";

interface FormatUrlProps extends RouteParams {
    building: BuildingType;
}

export const getBuildingRoute = (building: BuildingType) => `/${building}/:techSector?/:product?`;

export const formatBuildingRoute = ({
    building, techSector, product
}: FormatUrlProps) => {

    if (techSector && product) {
        return `/${building}/${techSector}/${product}`;
    }

    if (techSector) {
        return `/${building}/${techSector}`;
    }

    return `/${building}`;
}