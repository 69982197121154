import { AppDimensions } from "../../interfaces/app-dimensions";
import { createAction } from "../utils/store";


export const SET_APP_DIMENSIONS = 'SET_APP_DIMENSIONS';
export type SetAppDimensions = typeof SET_APP_DIMENSIONS;

export type SetAppDimensionsAction = {
    type: SetAppDimensions;
    payload: AppDimensions;
}

type Actions = SetAppDimensionsAction;

export const setAppDimensions = createAction<SetAppDimensions, AppDimensions>(SET_APP_DIMENSIONS);

export default Actions;